import React, { Fragment, useEffect, useState } from 'react'
import { EuiFormRow, EuiFieldText, EuiButton  } from '@elastic/eui'
import CONFIG from '../../../../config'
import Axios from 'axios'


const ProxyConfig = () => {

    const [proxySetting, changeProxySetting] = useState("")

    useEffect(()=>{

        if (CONFIG.mode !== "DEMO"){
            Axios.get(CONFIG.api.getProxy).then(resp => {
                if (resp.data){
                    changeProxySetting(resp.data['proxy'])    
                }
            })
        }

    },[])

    const handleProxyConfigChange = () => {
        if (CONFIG.mode !== "DEMO"){
            Axios.post(CONFIG.api.setProxy, {
                'proxy': proxySetting
            }).then( _ => alert("Proxy Changed"))
            .catch( err => {
                console.log(err)
                alert("Could not change proxy settings")
            })
        }
    }

    return  <Fragment>
                <EuiFormRow label="Proxy Settings for HTTP/S" helpText="In the form 'W.X.Y.Z:1234'">
                    <EuiFieldText
                        placeholder="Proxy Settings"
                        value={proxySetting}
                        onChange={(e) => changeProxySetting(e.target.value)}
                        aria-label="Proxy Settings"
                    />        
                </EuiFormRow>
                <EuiButton 
                    style={{marginTop: 22, marginLeft: 15}}
                    color="primary" 
                    fill={true}
                    onClick={handleProxyConfigChange}
                >
                    Submit
                </EuiButton>
            </Fragment>

}

export default ProxyConfig

