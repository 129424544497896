import React from 'react'

const Content = (props) => {

    return <section className="content">          
        {props.children}   
    </section>


}

export default Content