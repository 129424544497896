import Axios from 'axios';
import { ES_URL } from '../../../../../../config';

const EsTopWindows = (SearchField, SearchValue, TargetType, getElements, changeElements, startTime, endTime) => {

  const elements = getElements();

  const query = {
    'size': 0,
    '_source': {
      'excludes': []
    },
    'aggs': {
      'Target': {
        'terms': {
          'field': TargetType,
          'size': 25
        }
      }
    },
    'query': {
      'bool': {
        'must': [
          {
            'match_all': {}
          },
          {
            'range': {
              '@timestamp': {
                'gte': startTime,
                'lte': endTime,
                'format': 'epoch_millis'
              }
            }
          },
          {
            'match_phrase': {
              [SearchField]: {
                'query': SearchValue
              }
            }
          }
        ]
      }
    }
  };

  Axios.post(`${ES_URL}/winlogbeat-*/_search`, query).then(resp => {
    const buckets = resp.data.aggregations.Target.buckets;
    buckets.map(bucket => {

      if (bucket.key === SearchValue) {
        bucket.key = `${SearchValue}.${SearchField}`;
      }

      elements.push(
        {
          data: { id: bucket.key, size: Math.log10(bucket.doc_count+1) * 10, entityType: TargetType }
        }
      );
      elements.push(
        {
          data: { id: `${SearchValue}:${bucket.key}`, source: SearchValue, target: bucket.key }
        }
      );
    });
    changeElements(elements);
  });


};

export default EsTopWindows;
