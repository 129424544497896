import React, {useState, useEffect} from 'react'
import { Sparklines, SparklinesBars, SparklinesLine } from 'react-sparklines';
import CONFIG from '../../../../config';
import Axios from 'axios';
import WidgetScaffold from '../widgetScaffold'

function boxMullerRandom () {
    let phase = false,
        x1, x2, w;

    return (function() {

        if (phase = !phase) {
            do {
                x1 = 2.0 * Math.random() - 1.0;
                x2 = 2.0 * Math.random() - 1.0;
                w = x1 * x1 + x2 * x2;
            } while (w >= 1.0);

            w = Math.sqrt((-2.0 * Math.log(w)) / w);
            return x1 * w;
        } else {
            return x2 * w;
        }
    })();
}

function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
}


const TopSuspiciousHosts = (props) => {

    const [content, changeContent] = useState(null)
    const handleIPClick = (ipAddress) => () => {
        props.handleClick(ipAddress)
    }

    useEffect(()=>{
      switch (CONFIG.mode) {
        case 'PROD':
        case 'DEV':
          Axios.get(CONFIG.api.topSuspiciousHosts)
            .then(resp => {
              let newContent = resp.data.length > 0 ? resp.data.map(elem => <li>
                  <div className="row col-12" style={{width: "100%", padding: 4}}>
                    <div className="col-6" style={{marginTop: "auto"}}>
                        <h3 className="suspiciousHostIP"  onClick={handleIPClick(elem.ip)}>
                            {elem.ip}
                        </h3>                                
                    </div>
                    <div className="col-6" key={elem.ip}>
                        <Sparklines data={elem.timeline}>
                            <SparklinesBars style={{ stroke: "white", fill: "#41c3f9", fillOpacity: ".75" }} />
                            <SparklinesLine style={{ stroke: "#41c3f9", fill: "none", strokeWidth: 3}} />
                        </Sparklines>
                    </div>  
                    </div>                  
                </li> 
              ) : <ul className="stat__list">
                    No Data
                </ul>
            changeContent(<ul className="stat__list" style={{width: "100%"}}>
                {newContent}
            </ul>)
            })
            .catch(err => {
                console.log(err)
                changeContent(<ul className="stat__list">
                    No Data
                </ul>)
            })

          break;
      
        default:
            let newContent = <React.Fragment>
                          <div className="row" style={{width: "100%", padding: 15}}>
                            <div className="col-6">
                                <Sparklines data={randomData(30)}>
                                    <SparklinesBars style={{ stroke: "white", fill: "#41c3f9", fillOpacity: ".75" }} />
                                    <SparklinesLine style={{ stroke: "#41c3f9", fill: "none", strokeWidth: 3}} />
                                </Sparklines>
                            </div>
                            <div className="col-6" style={{textAlign: "center", marginTop: "auto"}}>
                                <h3>
                                    218.5.62.3
                                </h3>                                
                            </div>
                        </div> 
                        <div className="row" style={{width: "100%", padding: 15}}>
                            <div className="col-6">
                                <Sparklines data={randomData(30)}>
                                    <SparklinesBars style={{ stroke: "white", fill: "#41c3f9", fillOpacity: ".75" }} />
                                    <SparklinesLine style={{ stroke: "#41c3f9", fill: "none", strokeWidth: 3}} />
                                </Sparklines>
                            </div>
                            <div className="col-6" style={{textAlign: "center", marginTop: "auto"}}>
                                <h3>
                                    45.25.65.8
                                </h3>                                
                            </div>
                        </div>
                        <div className="row" style={{width: "100%", padding: 15}}>
                            <div className="col-6">
                                <Sparklines data={randomData(30)}>
                                    <SparklinesBars style={{ stroke: "white", fill: "#41c3f9", fillOpacity: ".75" }} />
                                    <SparklinesLine style={{ stroke: "#41c3f9", fill: "none", strokeWidth: 3}} />
                                </Sparklines>
                            </div>
                            <div className="col-6" style={{textAlign: "center", marginTop: "auto"}}>
                                <h3>
                                    188.50.1.1
                                </h3>                                
                            </div>
                        </div>      
                        <div className="row" style={{width: "100%", padding: 15}}>
                            <div className="col-6">
                                <Sparklines data={randomData(30)}>
                                    <SparklinesBars style={{ stroke: "white", fill: "#41c3f9", fillOpacity: ".75" }} />
                                    <SparklinesLine style={{ stroke: "#41c3f9", fill: "none", strokeWidth: 3}} />
                                </Sparklines>
                            </div>
                            <div className="col-6" style={{textAlign: "center", marginTop: "auto"}}>
                                <h3>
                                    23.5.87.123
                                </h3>                                
                            </div>
                        </div>  
                        <div className="row" style={{width: "100%", padding: 15}}>
                            <div className="col-6">
                                <Sparklines data={randomData(30)}>
                                    <SparklinesBars style={{ stroke: "white", fill: "#41c3f9", fillOpacity: ".75" }} />
                                    <SparklinesLine style={{ stroke: "#41c3f9", fill: "none", strokeWidth: 3}} />
                                </Sparklines>
                            </div>
                            <div className="col-6" style={{textAlign: "center", marginTop: "auto"}}>
                                <h3>
                                    8.4.5.6
                                </h3>                                
                            </div>
                        </div>    
          </React.Fragment>
          changeContent(newContent)
          break;
      }      
    },[])

    return <WidgetScaffold 
        title="Suspicious Hosts (24 Hrs)" 
        content={content}
    />

}

export default TopSuspiciousHosts