import React, { useState, useEffect } from 'react';
import CyGraph from './CyGraph';
import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiPageSideBar,
  EuiDatePicker,
  EuiDatePickerRange
} from '@elastic/eui';
import cyContext from '../cyContext';
import Sidebar from './Sidebar';
import moment from 'moment';
import AbnormalUserFileBehaviour from './AbnormalUserFileBehaviour';
import cytoscape from 'cytoscape';
import cxtmenu from 'cytoscape-cxtmenu';

cytoscape.use(cxtmenu);

const Main = (props) => {  

  // parse the arguments from the url
  let urlStartTime = moment().subtract(24, 'hours')
  let urlEndTime = moment()

  if (window.location.search !== ""){
    const searchStringParts = window.location.search.substr(1).split('&');
    searchStringParts.forEach(element => {
        const elementParts = element.split('=');
        if (elementParts.length === 2){
            const key = elementParts[0];
            const value = elementParts[1];

            switch (key) {
                case 'startTime':
                    urlStartTime = moment( parseInt(value) );
                    break;
                case 'endTime':
                    urlEndTime = moment( parseInt(value) );
                    break;
                default:
                    break;
            }

        }
    });
  }

  const [entityDetails, changeEntityDetails] = useState();
  const [rootEntity, changeRootEntity] = useState();
  const [startDate, changeStartDate] = useState( urlStartTime );
  const [endDate, changeEndDate] = useState(urlEndTime );

  useEffect(()=>{
    const loadScript = function (src) {
      const tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      window.document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('//cdnjs.cloudflare.com/ajax/libs/bokeh/1.2.0/bokeh.js');
    loadScript('//cdnjs.cloudflare.com/ajax/libs/bokeh/1.2.0/bokeh-widgets.js');
    loadScript('//cdnjs.cloudflare.com/ajax/libs/bokeh/1.2.0/bokeh-tables.js');
    loadScript('//cdnjs.cloudflare.com/ajax/libs/bokeh/1.2.0/bokeh-api.js');
  }, []);

  const handleEntityChange = (entity) => {
    if ((!entityDetails) || (!(entity.id === entityDetails.id))) {
      console.log(entity);
      changeEntityDetails(entity);
    }
  };

  const handleChangeStart = (date) => {
    console.log(date);
    changeStartDate(date);
  };

  const handleChangeEnd = (date) => {
    changeEndDate(date);
  };

  const handleChangeRootEntity = (newRootEntity) => {
    console.log("chaging root entity to", newRootEntity)
    changeRootEntity(newRootEntity)
  }

  useEffect(()=>console.log(props.rootElement),[props.rootElement])

  return (
    <cyContext.Provider value={{
      rootEntity: rootEntity,
      changeRootEntity: handleChangeRootEntity,
      handleEntityChange: handleEntityChange,
      startDate: startDate,
      endDate: endDate
    }}
    >
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bokeh/1.2.0/bokeh.min.css" />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bokeh/1.2.0/bokeh-widgets.min.css"/>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bokeh/1.2.0/bokeh-tables.css" />
      <EuiPage>
        <EuiPageBody>
          <EuiPageHeader>
            <EuiPageHeaderSection>
              <EuiDatePickerRange
                startDateControl={
                  <EuiDatePicker
                    selected={startDate}
                    onChange={handleChangeStart}
                    startDate={startDate}
                    endDate={endDate}
                    isInvalid={startDate > endDate}
                    aria-label="Start date"
                    showTimeSelect
                  />
                }
                endDateControl={
                  <EuiDatePicker
                    selected={endDate}
                    onChange={handleChangeEnd}
                    startDate={startDate}
                    endDate={endDate}
                    isInvalid={startDate > endDate}
                    aria-label="End date"
                    showTimeSelect
                  />
                }
              />
            </EuiPageHeaderSection>
          </EuiPageHeader>
          <EuiPageContent>
            {/* {window.location.search.substr(1).startsWith('visuals_windows_file_access') ? <AbnormalUserFileBehaviour /> : null} */}
            <CyGraph rootEntity={props.rootEntity}/>
          </EuiPageContent>
        </EuiPageBody>
        <EuiPageSideBar>
          <Sidebar entityDetails={entityDetails}/>
        </EuiPageSideBar>
      </EuiPage>
    </cyContext.Provider>
  );
};

export default Main;
