import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import CONFIG from '../../../../../config'

const SwiftAbnormalLoginTimeline = (props) => {

    const [chartOptions, changeChartOptions] = useState(null)

    useEffect(()=>{

        const initialChartOptions = {
            title: {
                text: 'Swift User Logins',
                textStyle: {
                    color: 'white'
                } 
            },
            legend: {
                data: ['bar']
            },
            toolbox: {},
            tooltip: {},
            yAxis: {
                max: function (value) {
                    return value.max *2;
                },
                axisLabel: {
                    color: 'white'
                } 
            },
            animationEasing: 'elasticOut',
            animationDelayUpdate: function (idx) {
                return idx * 5;
            }
        };
        

        if (CONFIG.mode !== "DEMO"){            

            const anomaly = props.anomaly
            const operator = anomaly.match(/^A user \((.*?)\).*/)[1]

            Axios.post(CONFIG.api.swiftAbnormalLoginTimeline, {
                'd': 7,
                'o': operator,
            }).then(resp => {
                const xAxisData = []
                const data1 = []
                resp.data.forEach(timestamp => {
                    const momentTimestamp = moment.utc(timestamp, "YYYY-MM-DDThh:mm:ss")
                    xAxisData.push(new Date(momentTimestamp))
                    data1.push(1)
                }); 
                changeChartOptions({
                    ...initialChartOptions,
                    xAxis: {
                        data: xAxisData,
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            color: 'white'
                        } 
                    },
                    series: [{
                        name: 'Login',
                        type: 'bar',
                        data: data1,
                        animationDelay: function (idx) {
                            return idx * 10;
                        }
                    }],
                })
            })
        } else {
            const data1 = []
            const xAxisData = []
            for (var i = 0; i < 10; i++) {
                var dt = new Date();
                dt.setDate( dt.getDate() - i );
                xAxisData.push(dt);
                data1.push(1);
            }

            changeChartOptions({
                ...initialChartOptions,
                xAxis: {
                    data: xAxisData,
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        color: 'white'
                    } 
                },
                series: [{
                    name: 'Login',
                    type: 'bar',
                    data: data1,
                    animationDelay: function (idx) {
                        return idx * 10;
                    }
                }],
            })
        }
        

    },[props.anomaly])

    return <div className="chart" style={{width: "100%", height: "100%"}}>
        { chartOptions ? <ReactEcharts option={chartOptions} />  : "Loading" }
    </div>
}

export default SwiftAbnormalLoginTimeline