import React, { useState } from 'react'
import UserTable from './widgets/configuration/userTable'
import { EuiTab } from '@elastic/eui'
import DataRetention from './widgets/configuration/dataRetention'
import Flows from './widgets/configuration/flows'
import SensitivityTuning from './widgets/configuration/sensitivityTuning';
import DashboardConfig from './widgets/configuration/dashboardConfig'
import ProxyConfig from './widgets/configuration/proxyConfig'
import ExternalIntegrations from './widgets/configuration/externalIntegrations'

const Configuration = () => {

    const tabs = ["Users", "Data Retention", "Flows", "Sensitivity Tuning", "Dashboards", "Proxy", "3rd Party Integration"]
    const tabContent = [<UserTable />, <DataRetention />, <Flows />, <SensitivityTuning />, <DashboardConfig />, <ProxyConfig />, <ExternalIntegrations />]
    const [selectedTab, changeSelectedTab] = useState(0)
    const [content, changeContent] = useState(<UserTable />)

    const handleTabSelected = (id) => {
        changeSelectedTab(id)
        changeContent(tabContent[id])
    }

    return <React.Fragment>
                
                    {
                        tabs.map((tab, id) => <EuiTab 
                            onClick={()=> handleTabSelected(id)}
                            isSelected={id === selectedTab}
                            disabled={false}
                            key={id}
                            >
                                {tab}
                            </EuiTab>
                        )
                    }                    
                    <div className="content__top-row" style={{marginTop: 15}}>    
                        {content}                            
                    </div>
                    
            </React.Fragment>    

}

export default Configuration