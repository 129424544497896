import React, {useState, useEffect, useLayoutEffect, Fragment} from 'react'
import CONFIG, { KB_URL } from '../../../../config'
import Axios from 'axios'
import BeatLoader from "react-spinners/BeatLoader";
import KbIndexId from '../common/kbIndexId';
import WidgetScaffold from '../widgetScaffold';

const MODAL_ID = 'winTopProvidersModal'

const generateEntryFromElem = (elem, winlogbeatIndexId) => {
    let icon
    switch (elem['threat']) {
        case 1:
            icon="success"
            break
        case 2:
            icon="warning"
            break
        case 3:
            icon="danger"
            break
        default:        
            icon="success"
            break    
    }
    return <li key={elem['provider']} className={`stat__iconed-item stat__iconed-item--${icon}`}>
                <a
                    href={`${KB_URL}/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(host.name,winlog.provider_name,winlog.event_id,log.level,message),index:'${winlogbeatIndexId}',interval:auto,query:(language:lucene,query:'winlog.provider_name:%22${elem['provider']}%22'),sort:!('@timestamp',desc))`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {elem['provider']}
                </a>
            </li>;
}

const WinTopProviders = () => {

    const [content, changeContent] = useState(null)    
    const [winlogbeatIndexId, changeWinlogbeatIndexId] = useState(null)

    useEffect(()=>{
        KbIndexId('winlogbeat').then(data => data ? changeWinlogbeatIndexId(data) : changeContent(<ul className="stat__list">
            No Data
        </ul>))
    },[])

    useEffect(()=>{
        switch (CONFIG.mode) {
            case 'PROD':
            case 'DEV':
                if (winlogbeatIndexId){
                    Axios.get(CONFIG.api.winTopProviders)
                        .then(resp => {
                            const data = resp.data
                            const formattedData = data.map(elem => generateEntryFromElem(elem, winlogbeatIndexId))
                            changeContent(<ul className="stat__list">
                                {formattedData}
                            </ul>)
                        })
                        .catch(err => console.log(err))
                }                
                break;
            default:
                    changeContent(<ul className="stat__list">
                        <li className="stat__iconed-item stat__iconed-item--danger">
                            Software Protection
                        </li>
                        <li className="stat__iconed-item stat__iconed-item--warning">
                            SecCli
                        </li>
                        <li className="stat__iconed-item stat__iconed-item--success">
                            Licensing Manager
                        </li>
                        <li className="stat__iconed-item stat__iconed-item--success">
                            Windows Firewall
                        </li>
                    </ul>
                    )
                break;
        }
    },[winlogbeatIndexId])

    const handleExpansion = () => {
        switch (CONFIG.mode) {
            case 'PROD':
            case 'DEV':
                window.open(`${KB_URL}/app/kibana#/visualize/create?type=table&indexPattern=${winlogbeatIndexId}&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-24h,mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(language:lucene,query:''),uiState:(vis:(params:(sort:(columnIndex:0,direction:desc)))),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'3',params:(extended_bounds:(),field:ML_confidence,interval:1),schema:bucket,type:histogram),(enabled:!t,id:'2',params:(field:winlog.provider_name,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:500),schema:bucket,type:terms),(enabled:!t,id:'4',params:(field:Abnormal_Notes,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:50),schema:bucket,type:terms)),params:(perPage:100,showMeticsAtAllLevels:!f,showPartialRows:!f,showTotal:!f,sort:(columnIndex:!n,direction:!n),totalFunc:sum),title:'Top%20Providers%20Windows',type:table))`, "_blank")
                break;
        
            default:
                break;
        }
    }

    return <WidgetScaffold 
            configureableLayout={true} 
            title="Top Log Sources (Windows)"
            titleClickHandler={handleExpansion}
            content={content}
        />   
        

}

WinTopProviders.displayName = "WinTopProviders"

export default WinTopProviders