import React, { useState, useContext } from 'react'
import { EuiIcon } from '@elastic/eui';
import { EuiPopover, EuiContextMenu } from '@elastic/eui';
import OperationsCenterContext from '../../../../contexts/operationsCenterContext';

function flattenPanelTree(tree, array = []) {
    array.push(tree);
  
    if (tree.items) {
      tree.items.forEach(item => {
        if (item.panel) {
          flattenPanelTree(item.panel, array);
          item.panel = item.panel.id;
        }
      });
    }
  
    return array;
  }

const ConfigureLayout = () => {

    const [position, changePosition] = useState(null)
    const operationsCenterContext = useContext(OperationsCenterContext)

    const panelTree = {
        id: 0,
        title: "Select Widget:",
        items: [
          {
            icon: 'indexPatternApp',
            name: 'IP',
            panel: {
                id: 1,
                title: 'IP Network Widgets',
                items: [
                  {
                    name: 'Top Sources',
                    onClick: () => {
                        changePopoverOpen(false);
                        operationsCenterContext.changeLayout({
                            widget: 'TopSources',
                            position: position
                        })
                      }
                  },
                  {
                    name: 'Top Destinations',
                    onClick: () => {
                        changePopoverOpen(false);
                        operationsCenterContext.changeLayout({
                            widget: 'TopDestinations',
                            position: position
                        })
                      }
                  },
                  {
                    name: 'Top Known Threats',
                    onClick: () => {
                        changePopoverOpen(false);
                        operationsCenterContext.changeLayout({
                            widget: 'TopKnownThreats',
                            position: position
                        })
                      }
                  },
                ]
            }            
          },
          {
            icon: 'logoWindows',
            name: 'Windows',
            panel: {
                id: 2,
                title: 'Windows Event Log Widgets',
                items: [
                  {
                    name: 'Top Sources',
                    onClick: () => {
                        changePopoverOpen(false);
                        operationsCenterContext.changeLayout({
                            widget: 'WinTopSources',
                            position: position
                        })
                      }
                  },
                  {
                    name: 'Top Providers',
                    onClick: () => {
                        changePopoverOpen(false);
                        operationsCenterContext.changeLayout({
                            widget: 'WinTopProviders',
                            position: position
                        })
                      }
                  },
                  {
                    name: 'Top Known Threats',
                    onClick: () => {
                        changePopoverOpen(false);
                        operationsCenterContext.changeLayout({
                            widget: 'WinTopKnownThreats',
                            position: position
                        })
                      }
                  },
                ]
            }            
          },
          {
            icon: 'indexManagementApp',
            name: 'System Elements',
            onClick: (e) => {
                changePopoverOpen(false);
                operationsCenterContext.changeLayout({
                  widget: 'SystemElements',
                  position: position
                })
              }     
          },
          {
            icon: 'trash',
            name: 'Delete Widget',
            onClick: (e) => {
                changePopoverOpen(false);
                operationsCenterContext.deleteWidget(position)
              }     
          }
        ]
    }
    
    const [popoverOpen, changePopoverOpen] = useState(false)

    return <div style={{
        position: "absolute",
        right: "30px",
        top: "10px"
    }}>
        <EuiPopover
            id="contextMenu"
            button={<EuiIcon type='gear' onClick={(e)=>{     
                changePosition(e.target.closest('.content__widget').dataset.position)           
                changePopoverOpen(true)
            }}/>}
            isOpen={popoverOpen}
            closePopover={()=>changePopoverOpen(false)}
            panelPaddingSize="none"
            anchorPosition="downLeft">
            <EuiContextMenu initialPanelId={0} panels={flattenPanelTree(panelTree)} />
        </EuiPopover>        
    </div>
}

export default ConfigureLayout