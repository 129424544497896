import React, {useState, useEffect} from 'react'
import CONFIG from '../../../../config'
import Axios from 'axios'
import ReactEcharts from 'echarts-for-react'
import BeatLoader from "react-spinners/BeatLoader";
import WidgetScaffold from '../widgetScaffold';


const RiskCategoryBreakdown = () => {

    const initialOptions = {
        tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
            textStyle:{
                color: 'white'
            },            
            bottom: 0,
            left: 'center',
            data:['Loading']
        },
        series: [
            {
                name:'Risk Categories',
                type:'pie',
                center: ['50%', '45%'],
                radius: ['50%', '70%'],
                avoidLabelOverlap: true,
                label: {
                    color: 'white',
                    normal: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        show: true,
                        textStyle: {
                            fontSize: '10',
                            fontWeight: 'bold'                            
                        }
                    }
                },
                labelLine: {
                    normal: {
                        show: false
                    }
                },
                data:[{
                    value: 0,
                    name: 'Loading'
                }]
            }
        ]
    };

    const [chartOptions, changeChartOptions] = useState(null)
    const [content, changeContent] = useState(null)

    useEffect(()=>{
        switch (CONFIG.mode) {
            case 'PROD':
            case 'DEV':
                Axios.get(CONFIG.api.riskCategories)
                    .then(resp => {
                        
                        const legend = resp.data.map(elem => elem.risk)
                        const chartData = resp.data.map(elem => {return {value: elem.percentage, name: elem.risk}})

                        let newChartOptions = {...initialOptions}

                        newChartOptions.legend.data = legend
                        newChartOptions.series[0].data = chartData   
                        changeChartOptions(newChartOptions)
                    })
                    .catch(err => console.log(err))                
                break;        
            default:
                let newChartOptions = {...initialOptions}
                newChartOptions.legend.data = ['Risk One', 'Risk Two', 'Risk Three']
                newChartOptions.series[0].data = [
                    {value:53.92, name:'Risk One'},
                    {value:42.3, name:'Risk Two'},
                    {value:12.86, name:'Risk Three'}
                ]                                    
                changeChartOptions(newChartOptions)
                break;
        }
    },[])

    useEffect(()=>{
        if (chartOptions){
            changeContent(<div className="chart" style={{width: "100%", height: "90%"}}>
                <ReactEcharts option={chartOptions} /> 
            </div>)
        }        
    },[chartOptions])

    return <WidgetScaffold 
        title="Risk Category Breakdown (24Hr)" 
        content={content}
    />

}

export default RiskCategoryBreakdown