import React, {useState, useEffect} from 'react'
import CONFIG, { KB_URL } from '../../../../config'
import Axios from 'axios'
import KbIndexId from '../common/kbIndexId';
import WidgetScaffold from '../widgetScaffold';


const generateEntryFromElem = (elem, filebeatIndexId) => {

    let icon

    switch (elem['threat']) {
        case 1:
            icon = "success"
            break
        case 2:
            icon = "warning"
            break
        case 3:
            icon = "danger"
            break
        default:
            icon = "success"
            break
    }

        return <li key={elem['ip']} className={`stat__iconed-item stat__iconed-item--${icon}`}>
            <a 
                target="_blank"
                rel="noopener noreferrer"
                href={`${KB_URL}/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(SourceAddress,DestinationAddress,DestinationPort,'ASN+Description',dst_geoip.country_code2),index:'${filebeatIndexId}',interval:auto,query:(language:lucene,query:'SourceAddress:%22${elem['ip']}%22'),sort:!('@timestamp',desc))`}
            >
                {elem['ip']}
            </a>            
        </li>
    
}

const TopSources = () => {

    const [content, changeContent] = useState(null)
    const [filebeatIndexId, changeFilebeatIndexId] = useState(null)
    const [alertIndexId, changeAlertIndexId] = useState(null)

    useEffect(()=>{
        KbIndexId('filebeat').then(data => data ? changeFilebeatIndexId(data) : changeContent(<ul className="stat__list">
            No Data
        </ul>))
    },[])
    
    useEffect(()=>{
        KbIndexId('alerts').then(data => data ? changeAlertIndexId(data) : null)
    },[])

    useEffect(()=>{
        switch (CONFIG.mode) {
            case 'PROD':
            case 'DEV':
                Axios.get(CONFIG.api.topSources)
                    .then(resp => {
                        const data = resp.data
                        const formattedData = data.map(elem => generateEntryFromElem(elem, filebeatIndexId))
                        changeContent(<ul className="stat__list" >
                            {formattedData}
                        </ul>)
                    })
                    .catch(err => {
                        console.log(err)
                        changeContent(<ul className="stat__list">
                            No Data
                        </ul>)
                    })
                break;
            default:
                    changeContent(<ul className="stat__list" >
                        <li className="stat__iconed-item stat__iconed-item--danger">
                            192.168.1.50
                        </li>
                        <li className="stat__iconed-item stat__iconed-item--warning">
                            192.168.1.5
                        </li>
                        <li className="stat__iconed-item stat__iconed-item--success">
                            192.168.2.5
                        </li>
                        <li className="stat__iconed-item stat__iconed-item--success">
                            192.168.2.50
                        </li>
                    </ul>
                    )
                break;
        }
    },[filebeatIndexId])

    
    const handleExpansion = () => {
        switch (CONFIG.mode) {
            case 'PROD':
            case 'DEV':
                window.open(`${KB_URL}/app/kibana#/visualize/create?type=table&indexPattern=${alertIndexId}&_g=(refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(filters:!(),linked:!f,query:(language:lucene,query:'*'),uiState:(vis:(params:(sort:(columnIndex:!n,direction:!n)))),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'4',params:(field:severity,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,row:!t,size:5),schema:split,type:terms),(enabled:!t,id:'3',params:(field:host,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:50),schema:bucket,type:terms)),params:(dimensions:(buckets:!((accessor:0,aggType:terms,format:(id:terms,params:(id:string,missingBucketLabel:Missing,otherBucketLabel:Other)),params:())),metrics:!((accessor:2,aggType:count,format:(id:number),params:())),splitRow:!((accessor:1,aggType:terms,format:(id:terms,params:(id:string,missingBucketLabel:Missing,otherBucketLabel:Other)),params:()))),perPage:10,showMetricsAtAllLevels:!f,showPartialRows:!f,showTotal:!f,sort:(columnIndex:!n,direction:!n),totalFunc:sum),title:'',type:table))`, "_blank")
                break;
        
            default:
                break;
        }
    }

    return <WidgetScaffold 
            configureableLayout={true} 
            title="Top Source Hosts"
            titleClickHandler={handleExpansion}
            content={content}
        />
}

TopSources.displayName = "TopSources";

export default TopSources