import React, {useState, Fragment, useEffect} from 'react';

import {
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask
} from '@elastic/eui';

const LoadingModal = (props) => {

  const [ isModalVisible, changeIsModalVisible ] = useState(props.isModalVisible);


  useEffect(()=>{
    changeIsModalVisible(props.isModalVisible);
  }, [props.isModalVisible]);

  return(
    <Fragment>
      {isModalVisible ?
        <EuiOverlayMask>
          <EuiModal onClose={()=>console.log('cannot close manually, sorry!')} >
            <EuiModalHeader>
              <EuiModalHeaderTitle>Loading</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              <EuiLoadingSpinner size="xl" /> <span style={{ margin: 'auto' }}>Please wait, this may take a couple of minutes...</span>
            </EuiModalBody>

            <EuiModalFooter />
          </EuiModal>
        </EuiOverlayMask>
        :
        null
      }
    </Fragment>
  );
};

export default LoadingModal;
