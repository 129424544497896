import React, {useState, useEffect} from 'react'
import CONFIG from '../../../../config'
import Axios from 'axios'
import ConfigureLayout from '../configuration/configureLayout'

const SuspiciousDetections = () => {

    const [counter, changeCounter] = useState(null)

    useEffect(()=>{
        switch ( CONFIG.mode ) {
            case 'PROD':
            case 'DEV':
                Axios.get(CONFIG.api.suspiciousDetections)
                    .then(resp => changeCounter(resp.data.suspectDetections))
                    .catch(err => console.log(err))
                break;
        
            default:
                changeCounter(50)
                break;
        }
    },[])

    return <div className="warning">
                <ConfigureLayout />
                <div className="warning__counter">{counter ? 
                    counter 
                : 
                    "N/A"
                }</div>
                <div className="warning__title">suspicious detections</div>
            </div>
}

SuspiciousDetections.displayName = "SuspiciousDetections"

export default SuspiciousDetections