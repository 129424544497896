import React, { useEffect, useState } from 'react'
import CONFIG, { KB_URL } from '../../config'
import Axios from 'axios'
import {EuiTabbedContent} from '@elastic/eui'

const Dashboard = () => {

    const defaultTabContent = [{
        id: 'no-dash-id',
        name: 'No Dashboards ',
        content: <p>
                No dashboard selected under Config > Dashboards
            </p>
    }]
    const [tabContent, changeTabContent] = useState(defaultTabContent)
    var [selectedTab, changeSelectedTab] = useState(0)
    const [autoRotateTimer, changeAutoRotateTimer] = useState(0)

    useEffect(()=>{
        if (CONFIG.mode !== "DEMO"){
            Axios.get(CONFIG.api.getDashboards).then(resp=>{
                let savedTimer;
                if (resp.data){                    
                    const enabledDashboards = resp.data.filter(elem => elem.default).map(elem => {
                        savedTimer = elem.timer
                        return {
                            id: elem.id,
                            name: elem.name,
                            content: <div >
                                <iframe 
                                    title={elem.name}
                                    src={`${KB_URL}/app/kibana#/dashboard/${elem.id}?embed=true&_g=(refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:lucene,query:''),timeRestore:!f,viewMode:view)`}
                                    height={window.innerHeight*0.95}
                                    width="100%" />
                                </div>
                        }
                    })

                    
                    if (enabledDashboards.length>0){
                        changeTabContent(enabledDashboards)   
                        changeAutoRotateTimer(savedTimer)               
                    }   
                }
            })
        }
    },[])

    useEffect(()=>{
        if (autoRotateTimer > 0){
            const interval = setInterval(() => {

                changeTabContent( prevTabContent => {
                    changeSelectedTab( prevSelectedTab => {
                        const tabIndex = ( prevSelectedTab +  1 ) % prevTabContent.length
                        console.log("Rotating to ", tabIndex)
                        return tabIndex;
                    })
                    return prevTabContent
                })                

              }, autoRotateTimer*60*1000);
              return () => clearInterval(interval);
        }        
    },[autoRotateTimer])

    return <React.Fragment>
        {tabContent ?
            <EuiTabbedContent
                tabs={tabContent}
                selectedTab={tabContent[selectedTab]}
                expand={true}
                onTabClick={tab => {
                    let tabIndex = tabContent.findIndex(elem => elem.id === tab.id)
                    debugger
                    changeSelectedTab(tabIndex)
                }}
            />
        :
            "Loading..."
        }
    </React.Fragment>

}

export default Dashboard