import React, {useState, useEffect, useLayoutEffect, Fragment} from 'react'
import CONFIG, { KB_URL } from '../../../../config'
import Axios from 'axios'
import KbIndexId from '../common/kbIndexId';
import WidgetScaffold from '../widgetScaffold';

const MODAL_ID = 'topDestinationsModal'


const generateEntryFromElem = (elem, filebeatIndexId) => {
    let icon

    switch (elem['threat']) {
        case 1:
            icon = "success"
            break
        case 2:
            icon = "warning"
            break
        case 3:
            icon = "danger"
            break
        default:
            icon = "success"
            break
    }

    return <li className={`stat__iconed-item stat__iconed-item--${icon}`} style={{
        wordBreak: "break-all"
    }}>
        <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${KB_URL}/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(SourceAddress,DestinationAddress,DestinationPort,'ASN+Description',dst_geoip.country_code2),index:'${filebeatIndexId}',interval:auto,query:(language:lucene,query:'DestinationAddress:%22${elem.ip}%22'),sort:!('@timestamp',desc))`}
        >
            {elem.ip}
        </a>        
    </li>
}

const TopDestinations = () => {

    const [content, changeContent] = useState(null)
    const [filebeatIndexId, changeFilebeatIndexId] = useState(null)    

    const handleExpansion = () => {
        switch (CONFIG.mode) {
            case 'PROD':
            case 'DEV':
                window.open(`${KB_URL}/app/kibana#/visualize/create?type=table&indexPattern=${alertsIndexId}&_g=(refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(filters:!(),linked:!f,query:(language:lucene,query:'*'),uiState:(vis:(params:(sort:(columnIndex:!n,direction:!n)))),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'4',params:(field:severity,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,row:!t,size:5),schema:split,type:terms),(enabled:!t,id:'3',params:(field:data.destination,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:50),schema:bucket,type:terms)),params:(dimensions:(buckets:!((accessor:1,aggType:terms,format:(id:terms,params:(id:string,missingBucketLabel:Missing,otherBucketLabel:Other)),params:())),metrics:!((accessor:2,aggType:count,format:(id:number),params:())),splitRow:!((accessor:0,aggType:terms,format:(id:terms,params:(id:string,missingBucketLabel:Missing,otherBucketLabel:Other)),params:()))),perPage:10,showMetricsAtAllLevels:!f,showPartialRows:!f,showTotal:!f,sort:(columnIndex:!n,direction:!n),totalFunc:sum),title:'',type:table))`, "_blank")
                break;
        
            default:
                break;
        }
    }

    useEffect(()=>{
        KbIndexId('filebeat').then(data => data ? changeFilebeatIndexId(data) : changeContent(<ul className="stat__list">
            No Data
        </ul>))
    },[])

    const [alertsIndexId, changeAlertsIndexId] = useState(null)

    useEffect(()=>{
        KbIndexId('alerts').then(data => data ? changeAlertsIndexId(data) : changeContent(<ul className="stat__list">
            No Data
        </ul>))
    },[])

    useEffect(()=>{
        switch (CONFIG.mode) {
            case 'PROD':
            case "DEV":
                Axios.get(CONFIG.api.topDestinations)
                    .then(resp => {
                        const newContent = resp.data.map(elem => generateEntryFromElem(elem, filebeatIndexId))
                        changeContent(<ul className="stat__list">
                            {newContent}
                        </ul>)
                    })
                    .catch(err => {
                        console.log(err)
                        changeContent(<ul className="stat__list">
                            No Data
                        </ul>)
                    })
                break;
            default:
                changeContent(<ul className="stat__list">
                        <li className="stat__iconed-item stat__iconed-item--success">
                            Facebook.com
                        </li>
                        <li className="stat__iconed-item stat__iconed-item--success">
                            Google.com
                        </li>
                        <li className="stat__iconed-item stat__iconed-item--success">
                            Bov.com
                        </li>
                        <li className="stat__iconed-item stat__iconed-item--warning">
                            k323232322222222...
                        </li>                    
                </ul>)
                break;
        }
    },[filebeatIndexId])


    return <WidgetScaffold 
            configureableLayout={true} 
            title="Top Destination Hosts"
            titleClickHandler={handleExpansion}
            content={content}
        />   
        
}

TopDestinations.displayName = "TopDestinations"

export default TopDestinations