import React, {useState, useEffect, useLayoutEffect, Fragment} from 'react'
import CONFIG, { KB_URL } from '../../../../config'
import Axios from 'axios'
import BeatLoader from "react-spinners/BeatLoader";
import WidgetScaffold from '../widgetScaffold';
import KbIndexId from '../common/kbIndexId';

const MODAL_ID = 'winTopKnownThreats'

const generateEntryFromElem = elem => {
    let icon
    switch (elem['severity']) {
        case 'low':
            icon="success"
            break
        case 'medium':
            icon="warning"
            break
        case 'high':
            icon="danger"
            break
        default:
            icon="success"
            break            
    }
    return <li key={elem['threat']} className={`stat__iconed-item stat__iconed-item--${icon}`}>
            {elem['threat']}
        </li>;
}

const WinTopKnownThreats = () => {

    const [content, changeContent] = useState(null)
    const [alertIndexId, changeAlertIndexId] = useState(null)

    useEffect(()=>{
        KbIndexId('alerts').then(data => data ? changeAlertIndexId(data) : null)
    },[])

    useEffect(()=>{
        switch (CONFIG.mode) {
            case 'PROD':
            case 'DEV':
                Axios.get(CONFIG.api.winTopKnownThreats)
                    .then(resp => {
                        const data = resp.data
                        const formattedData = data.map(elem => generateEntryFromElem(elem))
                        changeContent(<ul className="stat__list">
                            {formattedData}
                        </ul>)
                    })
                    .catch(err => console.log(err))
                break;
            default:
                    changeContent(<ul className="stat__list">
                        <li className="stat__iconed-item stat__iconed-item--danger">
                            Active Directory User Backdoors
                        </li>
                        <li className="stat__iconed-item stat__iconed-item--warning">
                            Suspicious Kerberos RC4 Ticket Encryption
                        </li>
                        <li className="stat__iconed-item stat__iconed-item--success">
                            User Password Changed
                        </li>
                        <li className="stat__iconed-item stat__iconed-item--success">
                            Abnormal Login
                        </li>
                    </ul>
                    )
                break;
        }
    },[])

    const handleExpansion = () => {
        switch (CONFIG.mode) {
            case 'PROD':
            case 'DEV':
                window.open(`${KB_URL}/app/kibana#/visualize/create?type=table&indexPattern=${alertIndexId}&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-24h,mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(language:lucene,query:''),uiState:(vis:(params:(sort:(columnIndex:!n,direction:!n)))),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'4',params:(field:severity.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,row:!t,size:5),schema:split,type:terms),(enabled:!t,id:'2',params:(field:title.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:500),schema:bucket,type:terms),(enabled:!t,id:'3',params:(field:description.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:5),schema:bucket,type:terms)),params:(perPage:10,showMeticsAtAllLevels:!f,showPartialRows:!f,showTotal:!f,sort:(columnIndex:!n,direction:!n),totalFunc:sum),title:'Top%20Known%20Alerts%20Windows',type:table))`, "_blank")
                break;
        
            default:
                break;
        }
    }

    return  <WidgetScaffold 
            configureableLayout={true} 
            title="Top Known Threats (Windows)"
            titleClickHandler={handleExpansion}
            content={content}
        />  
}

WinTopKnownThreats.displayName = "WinTopKnownThreats"

export default WinTopKnownThreats