import React, {useState, useLayoutEffect} from 'react'
import TopSources from './widgets/ip/topSources'
import TopKnownThreats from './widgets/ip/topKnownThreats'
import SystemElements from './widgets/common/systemElements'
import Alerts from './widgets/common/alerts'
import TopDestinations from './widgets/ip/topDestinations'
import SuspiciousDetections from './widgets/ip/suspiciousDetections'
import AlertDetails from './widgets/common/alertDetails'
import OperationsCenterContext from '../../contexts/operationsCenterContext'
import CONFIG from '../../config'
import Axios from 'axios'
import _ from 'lodash'

import RGL, { WidthProvider } from "react-grid-layout";
import 'react-grid-layout/css/styles.css' 
import 'react-resizable/css/styles.css' 
import WinTopSources from './widgets/windows/winTopSources'
import WinTopProviders from './widgets/windows/winTopProviders'
import WinTopKnownThreats from './widgets/windows/winTopKnownThreats'
import { EuiButton } from '@elastic/eui'
import { EuiHeader } from '@elastic/eui'
import { EuiHeaderSection } from '@elastic/eui'


const ReactGridLayout = WidthProvider(RGL);

const stringToLayout = (stringInput) => {
  switch (stringInput) {
    case 'TopSources':
        return <TopSources />      
    case 'TopDestinations':
        return <TopDestinations />
    case 'TopKnownThreats':
        return <TopKnownThreats />
    case 'SystemElements':
      return <SystemElements />
    case 'SuspiciousDetections':
      return <SuspiciousDetections />
    case 'WinTopSources':
      return <WinTopSources />
    case 'WinTopProviders':
      return <WinTopProviders />
    case 'WinTopKnownThreats':
      return <WinTopKnownThreats />
    default:
      break;
  }
}

const defaultGrid = [
  {w: 3, h: 2, x: 0, y: 0, i: '1', moved: false, static: false},
  {w: 3, h: 2, x: 3, y: 0, i: '2', moved: false, static: false},
  {w: 3, h: 2, x: 6, y: 0, i: '3', moved: false, static: false},
  {w: 3, h: 2, x: 9, y: 0, i: '4', moved: false, static: false},
  {w: 12, h: 2, x: 3, y: 2, i: '5', moved: false, static: false},
]

const defaultLayout = {
  "1": <SuspiciousDetections />,
  "2": <TopSources />,
  "3": <TopDestinations />,
  "4": <SystemElements/>,
  "5": <TopKnownThreats />
}

const OperationsCenter = () => {

  const [gridLayout, changeGridLayout] = useState(defaultGrid)

    const layoutToJson = (layoutInput) => {    
      const jsonLayout = {}
      Object.entries(layoutInput).forEach(entry => jsonLayout[entry[0]] = entry[1].type.displayName)
      return jsonLayout
    }

    const jsonToLayout = (jsonInput) => {
      const newLayout = {}
      Object.entries(jsonInput).forEach(entry => newLayout[entry[0]] = stringToLayout(entry[1]))
      return newLayout
    }

    const [eventDetails, changeEventDetails] = useState(null)
    const [layout, changeLayout] = useState({
      "1": null,
      "2": null,
      "3": null,
      "4": null,
      "5": null
    })

    const addWidget = () => {
      let newWidgetID = 1

      while (true) {
        if (Object.keys(layout).includes(String(newWidgetID)) || Object.keys(layout).includes(newWidgetID)){
          newWidgetID += 1
        } else {
          break
        }        
      }

      let newHeight = 0
      gridLayout.forEach(entry => entry.y > newHeight ? newHeight = entry.y : null)
      const newLayout = {
        position: newWidgetID,
        widget : 'TopSources'
      }
      const newGrid = [
        ...gridLayout,
        {w: 3, h: 2, x: 0, y: newHeight+2, i: `${newWidgetID}`, moved: false, static: false},
      ]
      
      changeLayoutHandler(newLayout, newGrid)
      
    }

    const deleteWidget = id => {

      let newLayout = {...layout}
      delete newLayout[parseInt(id)]
      const newGrid = gridLayout.filter(entry => entry.i !== id)
      
      changeLayoutHandler( null, newGrid, newLayout)
    }

    useLayoutEffect(()=>{
      if (CONFIG.mode !== "DEMO") {
        Axios.get(CONFIG.api.getOperationsCenterLayout)
          .then(resp => {
            if (resp.data.layout){
              const newLayout = jsonToLayout(resp.data.layout)
              const newGrid = resp.data.grid
              
              if (newLayout){
                changeLayout(newLayout)
              } else {
                changeLayout(defaultLayout)
              }

              if (newGrid){
                changeGridLayout(newGrid)
              }
                            
            } else {
              changeLayout(defaultLayout)
            }
          })
          .catch(err => {
            console.log("Warning, could not load layout, using default: ", err)            
            changeLayout(defaultLayout)            
          })
      } else {
        changeLayout(defaultLayout)
      }
    }, [])

    const changeLayoutHandler = (layoutObj, gridObj, newLayout) => {    

        if (!newLayout){
          newLayout = {
            ...layout,
            [layoutObj.position]: stringToLayout(layoutObj.widget)
          }
        }        

        changeLayout(newLayout)      
        if (gridObj) {
          changeGridLayout(gridObj)
        } else {
          gridObj = gridLayout
        }

        if (CONFIG.mode !== "DEMO"){
          Axios.post(CONFIG.api.setLayout, {
            'l': layoutToJson(newLayout),
            'g': gridObj,
            'p': 'operations-center'
          })
        }
        
    }

    const gridChangeHandler = newGrid => {
      if (!_.isEqual(newGrid, gridLayout)){
        changeGridLayout(newGrid)
        if (CONFIG.mode !== "DEMO"){
          Axios.post(CONFIG.api.setLayout, {
            'l': layoutToJson(layout),
            'g': newGrid,
            'p': 'operations-center'
          })
        }
      }      
    }
        

    return <React.Fragment>
        <div className="row">
                      
                <EuiHeader style={{
                    backgroundColor:"#1D1D1D",
                    width: "100%"
                }}>
                  <EuiHeaderSection grow={true} />
                  <EuiHeaderSection side="right" style={{marginBottom:10}}>

                      <EuiButton 
                        iconType="plusInCircleFilled"
                        color="white" 
                        size="s"
                        style={{
                          border: "solid",
                          borderWidth: "thin",
                          marginRight: 10
                        }}
                        onClick={addWidget}
                      >
                        Add Widget
                      </EuiButton>

                  </EuiHeaderSection>
                </EuiHeader>
            
          </div>

          <OperationsCenterContext.Provider value={{
              eventDetails: eventDetails,
              changeEventDetails: changeEventDetails,
              changeLayout: changeLayoutHandler,
              deleteWidget: deleteWidget
          }}>

          <ReactGridLayout 
            className="content__top-row layout" 
            layout={gridLayout} 
            cols={15} 
            rowHeight={150} 
            width={1200}
            onDragStop={gridChangeHandler}
            onResizeStop={gridChangeHandler}
          >
             {Object.keys(layout).map(key => 
              <div className="content__top-row-col content__widget" data-position={`${key}`} key={`${key}`}>
                  {layout[`${key}`]}
                </div>
              )}
               
            </ReactGridLayout>        
          
            <div className="content__main-row">
                <div className="content-view">
                  <div className="content-view__col" id="alertsList" style={{height: "80vh", overflowY: "scroll"}}>
                      <Alerts />
                  </div>

                  <div className="content-view__col">            
                      <AlertDetails />                
                  </div>
                </div>
            </div>
            
          </OperationsCenterContext.Provider>          
    </React.Fragment>

}

export default OperationsCenter