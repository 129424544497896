import React, { useState, useEffect, Fragment } from 'react'
import { EuiFieldNumber, EuiFormRow } from '@elastic/eui'
import Axios from 'axios'
import CONFIG from '../../../../config'
import { EuiButton } from '@elastic/eui'

const DataRetention = () => {

    const [retentionPeriod, changeRetentionPeriod] = useState(0)

    useEffect(() => {
        if (CONFIG.mode !== "DEMO"){
            Axios.get(CONFIG.api.dataRetention)
                .then(resp => changeRetentionPeriod(parseInt(resp.data.retentionPeriod)))
                .catch(_ => alert('Could not fetch retention period'))
        }
    },[])

    const handleRetentionPeriodChange = () => {
        if (CONFIG.mode !== "DEMO") {
            Axios.post(CONFIG.api.dataRetention, {
                'days': retentionPeriod
            }).then(_ => alert("Retention period changed"))
            .catch(err => alert("Could not change retention period"))
        }
    }

    return  <Fragment>
        <EuiFormRow label="Number of days to keep indices" helpText="Indices older than this will be deleted. Enter 0 for indefinite retention.">
            <EuiFieldNumber
                placeholder="Number of days to keep indices"
                value={retentionPeriod}
                onChange={(e) => changeRetentionPeriod(e.target.value)}
                aria-label="Number of days to keep indices"
            />        
        </EuiFormRow>
        <EuiButton 
            style={{marginTop: 22, marginLeft: 15}}
            color="primary" 
            fill={true}
            onClick={handleRetentionPeriodChange}
        >
            Submit
        </EuiButton>
    </Fragment>

}

export default DataRetention