import React, {useState, Fragment, useEffect} from 'react';

import {
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSuperSelect,
  EuiSpacer,
  EuiText
} from '@elastic/eui';

const RootNodeModal = (props) => {

  const [ isModalVisible, changeIsModalVisible ] = useState(props.isModalVisible);
  const [ entityValue, changeEntityValue ] = useState();
  const [ entityType, changeEntityType] = useState('ip_address');


  useEffect(()=>{
    changeIsModalVisible(props.isModalVisible);
  }, [props.isModalVisible]);

  const closeModal = () => {
    changeIsModalVisible(false);
  };

  const handleSave = () => {
    props.handleIpChange(entityValue, entityType, true);
    closeModal();
  };

  const onChange = (e) => {
    changeEntityValue(e.target.value);
  };

  const handleEntityTypeChange = (value) => {
    changeEntityType(value);
  };

  const entityOptions = [{
    value: 'ip_address',
    inputDisplay: 'IP Address',
    dropdownDisplay: (
      <Fragment>
        <strong>IP Address</strong>
        <EuiSpacer size="xs" />
        <EuiText size="s" color="subdued">
          <p className="euiTextColor--subdued">
            An IP address <br/> filebeat-* index: SourceAddress
          </p>
        </EuiText>
      </Fragment>
    ),
  }, {
    value: 'win_computername',
    inputDisplay: 'Windows Computer Name',
    disabled: true,
    dropdownDisplay: (
      <Fragment>
        <strong>Windows Computer Name</strong>
        <EuiSpacer size="xs" />
        <EuiText size="s" color="subdued">
          <p className="euiTextColor--subdued">
            A Computer name <br /> winlogbeat-* index: winlog.computer_name
          </p>
        </EuiText>
      </Fragment>
    ),
  }];

  return(
    <Fragment>
      {isModalVisible ?
        <EuiOverlayMask>
          <EuiModal onClose={closeModal} initialFocus="[name=popfirst]">
            <EuiModalHeader>
              <EuiModalHeaderTitle>Start exploring by entering an entity</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              <EuiForm>
                <EuiFormRow>
                  <EuiSuperSelect
                    options={entityOptions}
                    valueOfSelected={entityType}
                    onChange={handleEntityTypeChange}
                    itemLayoutAlign="top"
                    hasDividers
                  />
                </EuiFormRow>
                <EuiFormRow label="Value">
                  <EuiFieldText name="popfirst" onChange={onChange}/>
                </EuiFormRow>
              </EuiForm>
            </EuiModalBody>

            <EuiModalFooter>
              <EuiButton onClick={handleSave} fill>
                Save
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
        :
        null
      }
    </Fragment>
  );
};

export default RootNodeModal;
