import React, { useEffect, useState } from 'react'
import CONFIG from '../../../../config'
import Axios from 'axios'
import { EuiButton, EuiComboBox, EuiFieldNumber } from '@elastic/eui'
import { EuiFlexItem } from '@elastic/eui'
import { EuiFlexGrid } from '@elastic/eui'


const DashboardConfig = () => {

    const [selectedOptions, changeSelectedOptions] = useState([])
    const [allOptions, changeAllOptions] = useState([])
    const [autoRotateTimer, changeAutoRotateTimer] = useState(0)

    const handleTimerChange = e => {
        const sanitizedValue = parseInt(e.target.value, 10);
        isNaN(sanitizedValue) ? changeAutoRotateTimer(0) : changeAutoRotateTimer(sanitizedValue)
    }

    useEffect(()=>{

        if (CONFIG.mode !== "DEMO"){
            Axios.get(CONFIG.api.getDashboards).then(resp => {
                if (resp.data){
                    let timer = 0
                    const all_dashboards = resp.data.map(elem => {
                        timer = elem.timer
                        return {
                            label: elem.name,
                            'data-id': elem.id,
                        }
                    })                                       

                    const selected_dashboards = resp.data.filter(elem => elem.default).map(elem => ({
                        label: elem.name,
                        'data-id': elem.id,
                      }))

                    changeSelectedOptions(selected_dashboards)    
                    changeAllOptions(all_dashboards)    
                    changeAutoRotateTimer(timer)
                }
            })
        } else {
            const all_dashboards = [{
                label: 'Dash 1 Demo'
            }, {
                label: 'Dash 2 Demo'
            }]

            const selected_dashboards = [{
                label: 'Dash 1 Demo'
            }]

            changeAllOptions(all_dashboards)
            changeSelectedOptions(selected_dashboards)
        }

    },[])

    const handleOnChange = selectedOptions => {
        changeSelectedOptions(selectedOptions)
      };

    const handleSubmit = () => {
        console.log(selectedOptions)

        const dashNames = Array.from(selectedOptions.values()).map(elem=>elem.label).join(':::')
        const dashIDs = Array.from(selectedOptions.values()).map(elem=>elem['data-id']).join(':::')
        
        if (CONFIG.mode !== "DEMO"){
            Axios.post(CONFIG.api.setDefaultDashboard, {
                'n': dashNames,
                'id': dashIDs,
                't': autoRotateTimer
            }).then( _ => alert("Dashboard Changed"))
            .catch( err => {
                console.log(err)
                alert("Could not change dashboard")
            })
        }
    }

    return  <EuiFlexGrid columns={1} direction="column">
                <EuiFlexItem>
                    <EuiComboBox
                        placeholder="Select dashboards"
                        options={allOptions}
                        selectedOptions={selectedOptions}
                        onChange={handleOnChange}
                        onCreateOption={()=>alert("Dashboard does not exist")}
                        isClearable={true}
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    Auto rotate timer (in mins - enter '0' to disable) : 
                    <EuiFieldNumber
                        placeholder="Auto rotate (in mins - enter '0' to disable)"
                        value={autoRotateTimer}
                        onChange={handleTimerChange}
                        aria-label="Auto Rotate Timer"
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiButton 
                        style={{marginLeft: 5}}
                        color="primary" 
                        fill={true}
                        onClick={handleSubmit}
                    >
                        Submit
                    </EuiButton>
                </EuiFlexItem>        
            </EuiFlexGrid>    
}

export default DashboardConfig

