import React, { Fragment, useEffect, useState } from 'react';
import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui';
import EsAsnAbnormalDetails from '../data/esAsnAbnormalDetails';
import EsUserDetails from '../data/esUserDetails';
import ResearchIP from '../data/researchIP';
import EsClientAbnormalDetails from '../data/esClientAbnormalDetails';

const Sidebar = (props) => {

  const [view, changeView] = useState(null)

  useEffect(()=>{

    const entityDetails = props.entityDetails;
    console.log(entityDetails);

    let newView;
    if (entityDetails) {
      const entityDetailsKeys = Object.keys(entityDetails);

      // if (entityDetailsKeys.includes('abnormal')) {
      if (entityDetails.entityType === 'AnomalousASN') {
        newView = <EsAsnAbnormalDetails entityID={entityDetails.id} />
      } else if (entityDetails.entityType === 'AnomalousIPSource') {
        newView = <React.Fragment>
            <EsClientAbnormalDetails entityID={entityDetails.id} />
            <ResearchIP entityID={entityDetails.id} />
          </React.Fragment>
      } else if (entityDetails.entityType === 'ip_address_leaf') {
        newView = <ResearchIP entityID={entityDetails.id} />
      } else if (entityDetailsKeys.includes('user')) {
        newView = <EsUserDetails entityID={entityDetails.id} />
      }
    }

    changeView(newView)

  },[props.entityDetails])
  

  return (
    <Fragment>
      <EuiFlexGroup style={{ marginLeft: 10 }} justifyContent="spaceAround" alignItems="center" direction="column">
        <EuiFlexItem key={1}>
          <EuiCard
            title="Entity Details"
            description="Click on an entity for further details"
          />
        </EuiFlexItem>
        {view}
      </EuiFlexGroup>
    </Fragment>
  );

};

export default Sidebar;
