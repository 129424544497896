import React from 'react'

const ContentHeader = (props) => {

  let content = props.content
  if (props.link){
    content = <a href={props.link} target="_blank">
      {props.content}
    </a>
  }

    return <section className="content-header">
    <h1 id="content-header">{content}</h1>
  </section>
}

export default ContentHeader