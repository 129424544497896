import React from 'react'
import Axios from 'axios';
import CONFIG from '../config';

const MainHeader = (props) => {

  const handleLogout = () => {
    window.localStorage.removeItem('loggedIn');
    Axios.get(CONFIG.api.logout)
      .then(_ => window.location.reload())
      .catch(_ => window.location.reload())

  }

    return <header className="main-header">
    <a href="/#" className="logo">
      <span className="logo-mini"><img src="images/logo.svg" alt="logo"/></span>
      <span className="logo-lg"
        ><img src="images/logo.svg" alt="logo" /> <b>CyberSift</b></span>
    </a>
    <nav className="navbar navbar-static-top">
      {
        //<!-- Sidebar toggle button-->
        props.login ? 
          null 
        :
          <React.Fragment>
            <a
              href="/#"
              className="sidebar-toggle"
              data-toggle="push-menu"
              role="button"
            >
              <i className="fa fa-bars pull-right"></i>
              <span className="sr-only">Toggle navigation</span>
            </a>
            <div className="navbar-custom-menu">
              <ul className="nav navbar-nav">
                <li className="dropdown user user-menu">
                  <a href="/#" onClick={handleLogout}>
                    <span className="hidden-xs user-name">Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </React.Fragment>
      }
    </nav>
  </header>

}

export default MainHeader