import React, {useState, useContext, useEffect} from 'react'
import OperationsCenterContext from '../../../../contexts/operationsCenterContext'
import CONFIG from '../../../../config'
import Axios from 'axios'
import moment from 'moment'
import hash from 'object-hash'
import BeatLoader from "react-spinners/BeatLoader"
import { EuiIcon } from '@elastic/eui'
import { EuiTab } from '@elastic/eui'
import KbIndexId from '../common/kbIndexId'
import { KB_URL } from '../../../../config'

const demoData = [
    {
        type: "ip",
        timestamp: '06.07.2019 03:45',
        host: '192.168.2.50',
        destination: '125.45.6.3',
        anomaly: 'Behaviour/Statistical',
        service: '123',
        asn: 'ABC Inc.',
        abnormality: 'Too many connections, abnormal bandwidth',
        severity: 'medium',
    },
    {
        type: "win",
        timestamp: '07.07.2019 03:45',
        host: 'demo.tester.com',
        abnormality: 'Anomalous Sequence',
        anomaly: 'Behaviour',
        severity: 'high',
        score: 23.2,
        details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\nUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\nExcepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    {
        type: "swift",
        timestamp: '07.07.2019 03:45',
        host: '0.0.0.0',
        abnormality: 'Abnormal Number of SignOn events',
        anomaly: 'Behaviour',
        severity: 'high',
        score: 23.2,
        details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\nUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\nExcepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
]


const Alerts = () => {

    const operationsCenterContext = useContext(OperationsCenterContext)

    const [data, changeData] = useState(null)
    const [currentHost, changeCurrentHost] = useState(null);
    const [selectedTab, changeSelectedTab] = useState("ip_alerts")
    const [showAllAlertsButton, changeShowAllAlertsButton] = useState(null)

    const [overallWindowsSeverity, changeOverallWindowsSeverity] = useState(CONFIG.mode==="DEMO" ? "danger":"success")
    const [overallIpSeverity, changeOverallIpSeverity] = useState(CONFIG.mode==="DEMO" ? "warning":"success")
    const [overallSwiftSeverity, changeOverallSwiftSeverity] = useState(CONFIG.mode==="DEMO" ? "warning":null)

    const [ipAlertCounter, changeIpAlertCounter] = useState(CONFIG.mode==="DEMO" ? 10:0)
    const [winAlertCounter, changeWinAlertCounter] = useState(CONFIG.mode==="DEMO" ? 5:0)
    const [swiftAlertCounter, changeSwiftAlertCounter] = useState(CONFIG.mode==="DEMO" ? 9:0)

    const getData = (alertsType, keepData) => {
        let alertsURL
        let changeOverallSeverity
        let changeAlertCounter
        switch (alertsType) {
            case "ip_alerts":
                alertsURL = CONFIG.api.ipAlerts
                changeOverallSeverity = changeOverallIpSeverity
                changeAlertCounter = changeIpAlertCounter
                break;
            case "win_alerts":
                alertsURL = CONFIG.api.winAlerts
                changeOverallSeverity = changeOverallWindowsSeverity
                changeAlertCounter = changeWinAlertCounter
                break;
            case "swift_alerts":
                alertsURL = CONFIG.api.swiftAlerts
                changeOverallSeverity = changeOverallSwiftSeverity
                changeAlertCounter = changeSwiftAlertCounter
                break;
            default:
                break;
        }
        Axios.get(alertsURL)
            .then(resp => {

                if (resp.data === false){
                    return
                }

                let overallSeverity = 'low'
                let alertCounter = 0
                const newContent = resp.data.filter(elem => Object.keys(elem).includes('host')).map(elem => {
                    alertCounter ++
                    if (elem.severity === "high"){
                        overallSeverity = "high"
                    } else if (elem.severity === "medium" && overallSeverity !== "high"){
                        overallSeverity = "medium"
                    }

                    return elem
                })
                
                if (keepData){
                    changeData(newContent)
                }                

                switch (overallSeverity) {
                    case 'high':
                        overallSeverity = "danger"
                        break;
                    case 'medium':
                        overallSeverity = "warning"
                        break;                
                    default:
                        overallSeverity = "success"
                        break;
                }

                changeOverallSeverity(overallSeverity)
                changeAlertCounter(alertCounter)
                document.getElementById('alertsList').scrollTo(0,0)

                if (keepData){
                    operationsCenterContext.changeEventDetails(newContent[0])
                }                
            })
            .catch(err=>console.log(err))
    }
    

    useEffect(()=>{
        switch (CONFIG.mode) {
            case 'PROD':
            case 'DEV':
                getData(selectedTab, true)
                break;
        
            default:
                const filteredData = demoData.filter(elem => elem.type === selectedTab.split('_')[0])
                changeData(filteredData)
                break;
        }
    },[selectedTab])

    useEffect(()=>{
        //initial pull to get overall security
        getData("ip_alerts", true)
        getData("win_alerts")
        getData("swift_alerts")
    },[])

    useEffect(() => {
        let tags
        switch (selectedTab) {
            case "ip_alerts":
                tags="ip"
                break;
            case "win_alerts":
                tags="windows"
                break;
            case "swift_alerts":
                tags="swift"
                break;
            default:
                break;
        }
        KbIndexId('alerts').then(data => {

            changeShowAllAlertsButton(

                <a 
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${KB_URL}/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(severity,host,title,description,details),index:'${data}',interval:auto,query:(language:lucene,query:'tags:${tags}'),sort:!(severity,asc))`}
                    style={{marginLeft: "auto", marginRight: "auto", marginBottom: 10}}
                >
                    <button className="event-preview__btn"> 
                        Show All >>> 
                    </button>
                </a>             
                              
            );
      
          });
    },[selectedTab])

    const handleOnClick = (elem) => () => {
        operationsCenterContext.changeEventDetails(elem)
    }

    const handleBenignModal = (elem) => () => {
        changeCurrentHost(elem);
        window.$('#benignModal').modal('show')
    };

    const handleBenignWinAnomalousSequence = (elem) => () => {
        Axios.post(CONFIG.api.winBenignSequence, {
            id: elem.id
        }).then(_=>alert("Sequence marked as benign"))
        .catch(err=>{
            alert("Could not mark sequence as benign")
            console.error(err)
        })
    }

    const handleMaliciousModal = (elem) => () => {
        changeCurrentHost(elem);
        window.$('#maliciousModal').modal('show')
    };

    const handleAddition = (operation, typeOfAddition) => () => {
        if (CONFIG.mode !== "DEMO") {
            
            let URL
            switch (typeOfAddition) {
                case "malicious":
                    URL = CONFIG.api.ipMalicious
                    break;            
                case "benign":
                    URL = CONFIG.api.ipBenign
                    break;
                default:
                    break;
            }

            let payload = {
                'SourceAddress': '',
                'DestinationASN': '',
                'DestinationPort': ''
            }

            switch (operation) {
                case 'both':   
                    payload['SourceAddress'] = currentHost.host;
                    payload['DestinationASN'] = currentHost.asn;      
                    break;
                case 'src':    
                    payload['SourceAddress'] = currentHost.host;            
                    break;        
                case 'dst':                
                    payload['DestinationASN'] = currentHost.asn;      
                    break;
                default:
                    return;
            }

            Axios.post(URL, payload)
                .then(resp => {
                    resp.data.completed ? alert('Successfully added!') : alert('Operation could not be completed...');
                    window.$(`#${typeOfAddition}Modal`).modal('hide');
                })
                .catch(err => console.log(err))

        }        
    }

    const handleChangeTab = (tabType) => {
        changeData(null)
        changeSelectedTab(tabType)
    }

    
    return <React.Fragment>
                <div className="content-view__header" style={{width: "525px"}}>
                    Recent Alerts
                    <div>
                    <EuiTab
                        onClick={() => handleChangeTab('ip_alerts')}
                        isSelected={'ip_alerts' === selectedTab}
                        key='ip_alerts'
                    >
                        <span className={`stat__iconed-item stat__iconed-item--${overallIpSeverity}`}>
                            IP ({ipAlertCounter})
                        </span>
                    </EuiTab>
                    <EuiTab
                        onClick={() => handleChangeTab('win_alerts')}
                        isSelected={'win_alerts' === selectedTab}
                        key='win_alerts'
                    >
                        <span className={`stat__iconed-item stat__iconed-item--${overallWindowsSeverity}`}>
                            Windows ({winAlertCounter})
                        </span>
                    </EuiTab>
                    {overallSwiftSeverity ?
                        <EuiTab
                            onClick={() => handleChangeTab('swift_alerts')}
                            isSelected={'swift_alerts' === selectedTab}
                            key='swift_alerts'
                        >
                            <span className={`stat__iconed-item stat__iconed-item--${overallSwiftSeverity}`}>
                                SWIFT ({swiftAlertCounter})
                            </span>
                        </EuiTab>
                    :
                        null
                    }
                    </div>
                </div>
                <div className="event-preview__list" style={{width: "calc(100%-525px)"}}>
                    {
                        data ? data.map(elem => {

                            const alert_summary = {
                                'icon': <EuiIcon type='gear' style={{ margin: 10}}/>
                            }

                            switch (elem.severity) {
                                case "high":
                                    alert_summary.icon = "danger"
                                    break;
                                case "medium":
                                    alert_summary.icon = "warning"
                                    break;
                                case "low":
                                    alert_summary.icon = "success"
                                    break;
                                default:
                                    break;
                            }

                            return <div className="event-preview" key={hash.MD5(elem)}>
                                <div className="event-preview__date">
                                    <span className={`stat__iconed-item stat__iconed-item--${alert_summary.icon}`}>
                                        {Number(elem.timestamp) ? moment.utc(parseInt(elem.timestamp), 'x').format('h:mm:ssA, Do MMMM YYYY') : moment.utc(elem.timestamp, "YYYY-MM-DDThh:mm:ss").format('h:mm:ssA, Do MMMM YYYY')}
                                    </span>
                                </div>

                                <div className="event-preview__details">
                                <div className="event-preview__details-row">
                                    <div className="event-preview__details-col">
                                        HOST
                                    </div>
                                    <div className="event-preview__details-col">
                                        {elem.host}
                                    </div>
                                </div>

                                <div className="event-preview__details-row">
                                    <div className="event-preview__details-col">
                                        SEVERITY
                                    </div>
                                    <div className="event-preview__details-col">
                                        {elem.severity}
                                    </div>
                                </div>

                                <div className="event-preview__details-row">
                                    <div className="event-preview__details-col">
                                        THREAT TYPE
                                    </div>
                                    <div className="event-preview__details-col">
                                        {elem.anomaly}
                                    </div>
                                </div>

                                <div className="event-preview__details-row">
                                    <div className="event-preview__details-col">
                                        {elem.abnormality.startsWith("http") ? "Source(s)" : "Reason(s)"}
                                    </div>
                                    <div className="event-preview__details-col">
                                        {elem.abnormality}
                                    </div>
                                </div>

                                <div className="event-preview__controls">
                                    {elem.type === "ip" ?
                                    <React.Fragment>                                    
                                        <button className="event-preview__btn event-preview__btn--danger" onClick={handleMaliciousModal(elem)}>
                                            Malicious
                                        </button>
                                        <button className="event-preview__btn event-preview__btn--info" onClick={handleBenignModal(elem)}>
                                            Benign
                                        </button>
                                    </React.Fragment>
                                    :
                                        elem.anomaly === "Anomalous Sequence" ? <React.Fragment>                                    
                                            <button className="event-preview__btn event-preview__btn--info" onClick={handleBenignWinAnomalousSequence(elem)}>
                                                Benign
                                            </button>
                                        </React.Fragment>
                                        : 
                                        null
                                    }
                                    <button className="event-preview__btn" onClick={handleOnClick(elem)}>
                                        Show Details
                                    </button>
                                </div>
                                </div>
                        </div>
                    })
                    :
                        <div
                            style={{
                                marginTop: 15,
                                marginLeft: "auto",
                                marginRight: "auto"
                            }}
                        >
                            <BeatLoader
                                loading={true}
                                color="#36D7B7"
                            /> 
                        </div>
                    }

                    {showAllAlertsButton}       
                    
                    <div class="modal" tabIndex="-1" role="dialog" id="benignModal">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 className="modal-title">Mark as Benign</h5>
                                <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <button 
                                    className="event-preview__btn event-preview__btn--success" 
                                    style={{fontWeight: "bolder", width: "100%"}} 
                                    onClick={handleAddition('both', 'benign')}
                                >
                                    Mark (Source IP + Destination ASN) as benign <br/> (recommended)
                                </button>
                                <button 
                                    className="event-preview__btn event-preview__btn--success" 
                                    style={{fontWeight: "bolder", width: "100%"}} 
                                    onClick={handleAddition('src', 'benign')}
                                >
                                    Mark Source IP address as benign <br/> (advanced users only)
                                </button>
                                <button 
                                    className="event-preview__btn event-preview__btn--success" 
                                    style={{fontWeight: "bolder", width: "100%"}} 
                                    onClick={handleAddition('dst', 'benign')}
                                >
                                    Mark Destination ASN as benign <br/> (advanced users only)
                                </button>                                
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal" tabindex="-1" role="dialog" id="maliciousModal">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Mark as Malicious</h5>
                                <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <button 
                                    className="event-preview__btn event-preview__btn--success" 
                                    style={{fontWeight: "bolder", width: "100%"}} 
                                    onClick={handleAddition('both', 'malicious')}
                                >
                                    Mark (Source IP + Destination ASN) as malicious <br/> (recommended)
                                </button>
                                <button 
                                    className="event-preview__btn event-preview__btn--success" 
                                    style={{fontWeight: "bolder", width: "100%"}} 
                                    onClick={handleAddition('src', 'malicious')}
                                >
                                    Mark Source IP address as malicious <br/> (advanced users only)
                                </button>
                                <button 
                                    className="event-preview__btn event-preview__btn--success" 
                                    style={{fontWeight: "bolder", width: "100%"}} 
                                    onClick={handleAddition('dst', 'malicious')}
                                >
                                    Mark Destination ASN as malicious <br/> (advanced users only)
                                </button>                                
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                            </div>
                        </div>
                    </div>


                </div>
            </React.Fragment>
}

export default Alerts