let MODE = window.location.host === "localhost:3000" ? 'DEV' : 'PROD' // DEMO | PROD | DEV
let ES_URL = 'http://localhost:8088/cs_api/es_proxy';
let KB_API_URL = 'http://localhost:8088/cs_api/kb_proxy';
let KB_URL = 'http://localhost:5601';
let NIFI_URL = "http://localhost:8080/nifi/";
let API_URL = 'http://localhost:8080';

// local dev
if (MODE === 'DEV'){
    ES_URL = 'http://localhost:8088/cs_api/es_proxy';
    KB_API_URL = 'http://localhost:8088/cs_api/kb_proxy';
    KB_URL = '';
    NIFI_URL = "http://localhost:8080/nifi/";
    API_URL = 'http://localhost:8080';
} else {
    // PROD - production
    ES_URL = '/cybersift_elasticsearch';
    KB_API_URL = '/cs_api/kb_proxy';
    KB_URL = '';
    NIFI_URL = "/nifi/";
    API_URL = '';
}



const getBASEURL = () => {
    switch (MODE) {
        case 'DEV':
            return "./cs_api"    
        case 'PROD':
            return "/cs_api" 
        default:
            break;
    }
}

const getURL = (key) => {
    switch (key) {
        case 'login':
            return getBASEURL()+'/login'
        case 'logout':
            return getBASEURL()+'/logout'
        case 'systemStatus':
            return getBASEURL()+'/system/status'
        case 'systemStatusIP':
            return getBASEURL()+'/ip/status'
        case 'systemStatusDNS':
                return getBASEURL()+'/dns/status'
        case 'systemStatusWINDOWS':
            return getBASEURL()+'/windows/status'
        case 'ipTopCountries':
            return getBASEURL()+'/ip/topCountries'
        case 'ipTopASNs':
            return getBASEURL()+'/ip/topASNs'
        case 'asnBandwith':
            return getBASEURL()+'/ip/asnBandwith'
        case 'topSources':
            return getBASEURL()+'/ip/topSources'
        case 'topSourcesExpanded':
            return getBASEURL()+'/ip/topSources/25'
        case 'topKnownThreats':
            return getBASEURL()+'/ip/topKnownThreats'
        case 'riskCategories':
            return getBASEURL()+'/ip/riskCategories'
        case 'offendingLocations':
            return getBASEURL()+'/ip/offendingLocations'
        case 'asnInformation':
            return getBASEURL()+'/ip/asnInformation'
        case 'topDestinations':
            return getBASEURL()+'/ip/topDestinations'
        case 'topDestinationsExpanded':
            return getBASEURL()+'/ip/topDestinations/25'
        case 'suspiciousDetections':
            return getBASEURL()+'/ip/suspiciousDetections'
        case 'topSuspiciousHosts':
            return getBASEURL()+'/ip/topSuspiciousHosts'
        case 'ipAlerts':
            return getBASEURL()+'/alerts/ip'
        case 'ipBenign':
            return getBASEURL()+'/ip/benign'
        case 'ipMalicious':
            return getBASEURL()+'/ip/malicious'        
        case 'delIpBenign':
            return getBASEURL()+'/ip/benign/delete'
        case 'delIpMalicious':
            return getBASEURL()+'/ip/malicious/delete' 
        case 'userGetAll':
                return getBASEURL()+'/users'
        case 'userCreate':
            return getBASEURL()+'/users/create'        
        case 'userDelete':
            return getBASEURL()+'/users/delete'
        case 'getOperationsCenterLayout':
            return getBASEURL()+'/ui/layouts/operationsCenter'
        case 'setLayout':
            return getBASEURL()+'/ui/layouts'
        case 'winTopSources':
            return getBASEURL()+'/win/topSources'
        case 'winTopSourcesExpanded':
            return getBASEURL()+'/win/topSources/25'
        case 'winTopProviders':
            return getBASEURL()+'/win/topProviders'
        case 'winTopProvidersExpanded':
            return getBASEURL()+'/win/topProviders/25'
        case 'winTopKnownThreats':
            return getBASEURL()+'/win/topKnownThreats'
        case 'winTopKnownThreatsExpanded':
            return getBASEURL()+'/win/topKnownThreats/25'
        case 'winAlerts':
            return getBASEURL()+'/alerts/win'
        case 'swiftAlerts':
            return getBASEURL()+'/alerts/swift'
        case 'asnAbnormalDetails':
            return getBASEURL()+'/ip/asnAnomalyDetails'
        case 'dataRetention':
            return getBASEURL()+'/system/ilm'
        case 'getDashboards':
            return getBASEURL()+'/ui/dashboards'
        case 'setDefaultDashboard':
            return getBASEURL()+'/ui/dashboard'
        case 'getIpMaliciousBengin':
            return getBASEURL()+'/ip/maliciousBenign'
        case 'getProxy':
            return getBASEURL()+'/system/proxy'
        case 'setProxy':
            return getBASEURL()+'/system/proxy'
        case 'getS1Settings':
            return getBASEURL()+'/system/S1Settings'
        case 'setS1Settings':
            return getBASEURL()+'/system/S1Settings'
        case 'toggle2FA':
            return getBASEURL()+'/toggleOTP'
        case 'get2FASecret':
            return getBASEURL()+'/users/2faSecret'
        case 'checkValidLogin':
            return getBASEURL()+'/checkUserLogin'
        case 'resolveAlert':
            return getBASEURL()+'/alerts/resolve'
        case 'heartbeat':
            return getBASEURL()+'/auth/heartbeat'
        default:
            break;
    }
}

const CONFIG = {
    mode: MODE,
    api: {
        login: getURL('login'),
        logout: getURL('logout'),
        ipTopCountries: getURL('ipTopCountries'),
        ipTopASNs: getURL('ipTopASNs'),
        asnBandwith: getURL('asnBandwith'),
        topSources: getURL('topSources'),
        topSourcesExpanded: getURL('topSourcesExpanded'),
        topKnownThreats: getURL('topKnownThreats'),
        riskCategories: getURL('riskCategories'),
        offendingLocations: getURL('offendingLocations'),
        asnInformation: getURL('asnInformation'),
        topDestinations: getURL('topDestinations'),
        topDestinationsExpanded: getURL('topDestinationsExpanded'),
        suspiciousDetections: getURL('suspiciousDetections'),
        systemStatusIP: getURL('systemStatusIP'),
        systemStatusDNS: getURL('systemStatusDNS'),
        systemStatusWINDOWS: getURL('systemStatusWINDOWS'),
        systemStatus: getURL('systemStatus'),
        topSuspiciousHosts: getURL('topSuspiciousHosts'),
        ipAlerts: getURL('ipAlerts'),
        getIpMaliciousBengin: getURL('getIpMaliciousBengin'),
        ipBenign: getURL('ipBenign'),
        ipMalicious: getURL('ipMalicious'),        
        delIpBenign: getURL('delIpBenign'),
        delIpMalicious: getURL('delIpMalicious'),        
        userGetAll: getURL('userGetAll'),
        userCreate: getURL('userCreate'),
        userDelete: (username) => `${getURL('userDelete')}/${username}`,
        getOperationsCenterLayout: getURL('getOperationsCenterLayout'),
        setLayout: getURL('setLayout'),
        winTopSources: getURL('winTopSources'),
        winTopSourcesExpanded: getURL('winTopSourcesExpanded'),
        winTopProviders: getURL('winTopProviders'),
        winTopProvidersExpanded: getURL('winTopProvidersExpanded'),
        winTopKnownThreats: getURL('winTopKnownThreats'),
        winTopKnownThreatsExpanded: getURL('winTopKnownThreatsExpanded'),
        winAlerts: getURL('winAlerts'),
        swiftAlerts: getURL('swiftAlerts'),
        asnAbnormalDetails: getURL('asnAbnormalDetails'),
        dataRetention: getURL('dataRetention'),
        getDashboards: getURL('getDashboards'),
        setDefaultDashboard: getURL('setDefaultDashboard'),
        setProxy: getURL('setProxy'),
        getProxy: getURL('getProxy'),
        getS1Settings: getURL('getS1Settings'),
        setS1Settings: getURL('setS1Settings'),
        toggle2FA: getURL('toggle2FA'),
        get2FASecret: getURL('get2FASecret'),
        checkValidLogin: getURL('checkValidLogin'),
        resolveAlert: getURL('resolveAlert'),
        heartbeat: getURL('heartbeat'),
    }
}

export {
    ES_URL,
    KB_API_URL,
    KB_URL,
    NIFI_URL,
    API_URL,
}
export default CONFIG