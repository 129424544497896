import React from 'react'
import { NIFI_URL } from '../../../../config'


const Flows = () => {

    return  <div  style={{width: "100%"}}>
      <iframe 
          title="Network Dashboard"
          src={`${NIFI_URL}`}
          height={window.innerHeight*0.95}
          width="100%" />
    </div>

}

export default Flows