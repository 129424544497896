import React, {useState, useEffect} from 'react'
import BeatLoader from "react-spinners/BeatLoader";
import Axios from 'axios';
import CONFIG from '../../../../config';
import { EuiIcon } from '@elastic/eui';

const SensitivtyTuning = () => {

    const [content, changeContent] = useState(null)
    const [tuningObjects, changeTuningObjects] = useState([])

    const getTuningObjects = () => {
        switch (CONFIG.mode) {   
            case 'PROD':         
            case "DEV":
                Axios.get(CONFIG.api.getIpMaliciousBengin).then(resp => {
                    const tuningObjects = resp.data
                    changeTuningObjects(tuningObjects)
                })
                .catch(err => console.log(err))       
                break;        
            default:
                const tuningObjects = [
                    {
                        'type': 'ip_malicious',
                        'SourceAddress': '192.168.1.2',
                        'DestinationASN': 'FACEBOOK',
                        'DestinationPort': 5222
                    },
                    {
                        'type': 'ip_malicious',
                        'SourceAddress': '192.168.1.1',
                        'DestinationASN': '',
                        'DestinationPort': ''
                    },
                    {
                        'type': 'ip_benign',
                        'SourceAddress': '192.168.1.1',
                        'DestinationASN': 'GOOGLE',
                        'DestinationPort': ''
                    }
                ]                
                changeTuningObjects(tuningObjects)
                break;
        } 
    }
    

    const handleSwitchClass = (tuningObj) => () => {

        if (!window.confirm("Are you sure?")){
            return
        }

        let deleteURL
        let createURL
        let newType

        switch (tuningObj.type) {
            case "ip_malicious":
                deleteURL = CONFIG.api.delIpMalicious
                createURL = CONFIG.api.ipBenign
                newType = "ip_benign"
                break;
            case "ip_benign":
                deleteURL = CONFIG.api.delIpBenign
                createURL = CONFIG.api.ipMalicious
                newType = "ip_malicious"
                break;
            default:
                break;
        }

        const newObj = {
            ...tuningObj,
            type: newType
        }

        if (CONFIG.mode !== "DEMO"){

            Axios.all([
                Axios.post(deleteURL, tuningObj),
                Axios.post(createURL, newObj)
            ]).catch(err => {
                alert("Unable to switch classificaion")
            })
        }
        
        
        changeTuningObjects(tuningObjects.map(obj => {
            if(JSON.stringify(tuningObj) === JSON.stringify(obj)){
                return newObj
            } 

            return obj
        }))
        
    }

    const handleDelete = (obj) => () => {
        if (window.confirm(`Are you sure?`)){

            if (CONFIG.mode === "DEMO") {
                changeTuningObjects(tuningObjects.filter(tuningObject => JSON.stringify(tuningObject) !== JSON.stringify(obj)))
            } else {
                let url

                switch (obj.type) {
                    case "ip_malicious":
                        url = CONFIG.api.delIpMalicious
                        break;
                    case "ip_benign":
                        url = CONFIG.api.delIpBenign
                        break;
                    default:
                        break;
                }

                Axios.post(url, obj)
                    .then( resp => {
                        changeTuningObjects(tuningObjects.filter(tuningObject => JSON.stringify(tuningObject) !== JSON.stringify(obj)))
                    })
                    .catch( err => alert(`Could not delete object`))
            }

            
        }        
    }

    

    useEffect(()=>{
        changeContent( <div className="col-9">
        <table className="table table-hover">
                <thead>
                <tr>
                    <th scope="col">
                        <h5>Type</h5>
                    </th>
                    <th scope="col">
                        <h5>Source Address</h5>
                    </th>
                    <th scope="col">
                        <h5>Destination ASN</h5>
                    </th>
                    <th scope="col">
                        <h5>Destination Port</h5>
                    </th>
                    <th scope="col">
                        <h5>Actions</h5>
                    </th>
                </tr>
                </thead>
                <tbody>
                    {tuningObjects.map( (tuningObj, idx) => <tr key={idx}>
                            <td style={{verticalAlign: "middle"}}>
                                {tuningObj.type}
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                {tuningObj.SourceAddress}
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                {tuningObj.DestinationASN}
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                {tuningObj.DestinationPort}
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <button 
                                    className="btn btn-outline-light btn-sm" 
                                    style={{padding: 10, margin: 5, borderStyle: "solid", borderWidth: 1}}
                                    onClick={handleDelete(tuningObj)}
                                >
                                    <svg className="bi bi-x-circle-fill" width="1.5em" height="1.5em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7.354 6.646L10 9.293l2.646-2.647a.5.5 0 01.708.708L10.707 10l2.647 2.646a.5.5 0 01-.708.708L10 10.707l-2.646 2.647a.5.5 0 01-.708-.708L9.293 10 6.646 7.354a.5.5 0 11.708-.708z" clipRule="evenodd"></path>
                                    </svg>
                                    <span style={{paddingLeft: 5}}>Delete</span>
                                </button>  
                                <button 
                                    className="btn btn-outline-light btn-sm" 
                                    style={{padding: 10, margin: 5, borderStyle: "solid", borderWidth: 1}}
                                    onClick={handleSwitchClass(tuningObj)}
                                >
                                    <EuiIcon type="expand" />
                                    
                                    <span style={{paddingLeft: 5}}>Switch Classification</span>
                                </button>                   
                                
                            </td>
                        </tr>)}          
                </tbody>
            </table>
        </div>
        )
    },[tuningObjects])        

    useEffect(()=>{

        getTuningObjects()       
        
    },[])

    return <React.Fragment>
        { content ? content
        : 
            <BeatLoader
                loading={true}
                color="#36D7B7"
            /> 
        }
    </React.Fragment>
        
}

export default SensitivtyTuning