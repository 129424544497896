import React, {useContext, useEffect, useState, Fragment} from 'react'
import OperationsCenterContext from '../../../../contexts/operationsCenterContext'
import CONFIG, { KB_URL } from '../../../../config'
import { EuiIcon } from '@elastic/eui'
import KbIndexId from '../common/kbIndexId'
import AsnAbnormalDetails from '../ip/asnAbnormalDetails'
import Axios from 'axios'
import LoginContext from '../../../../contexts/loginContext'
import SwiftAbnormalLoginTimeline from '../windows/swift/swiftAbnormalLoginTimeline'

const AlertDetails = () => {
    
    const operationsCenterContext = useContext(OperationsCenterContext)
    const [filebeatIndexId, changeFilebeatIndexId] = useState(null)
    const loginContext = useContext(LoginContext)

    const handleResearch = (provider) => () => {
        if (CONFIG.mode === "DEMO") {
            return
        }
        switch (provider) {
            case "otx":  
                window.open(`https://otx.alienvault.com/indicator/ip/${operationsCenterContext.eventDetails.destination}`, "_blank");
                break;
            case "vt":                
                window.open(`https://www.virustotal.com/graph/${operationsCenterContext.eventDetails.destination}`, "_blank");
                break;
            case "xf":
                window.open(`https://exchange.xforce.ibmcloud.com/ip/${operationsCenterContext.eventDetails.destination}`, "_blank");                
                break;
            case "tc":
                window.open(`https://www.threatcrowd.org/ip.php?ip=${operationsCenterContext.eventDetails.destination}`, "_blank");
                break;
            default:
                break;
        }
    }

    const handleAlertResolved = () => {
        const alertID = operationsCenterContext.eventDetails.id
        const alertIndex = operationsCenterContext.eventDetails.index
        const loggedInUser = loginContext.loggedInUser

        Axios.post(CONFIG.api.resolveAlert, {
            id: alertID,
            index: alertIndex,
            user: loggedInUser
        }).then(_=> alert("Alert marked as resolved"))
        .catch(err=>{
            console.log(err)
            alert("Could not resolve alert")
        })

    }

    const [eventDetails, changeEventDetails] = useState(null)

    useEffect(()=>{
        KbIndexId('filebeat').then(data => changeFilebeatIndexId(data))
    }, [])

    useEffect(()=>{

        if (!operationsCenterContext.eventDetails){
            return
        }

        let space1Heading = ""
        let space1Content = ""
        let space2Heading = ""
        let space2Content = ""
        let space3Heading = ""
        let space3Content = ""
        let space4Heading = ""
        let space4Content = ""
        let icon = <EuiIcon type='gear' style={{ margin: 10}}/>

        switch (operationsCenterContext.eventDetails.type) {
            case 'ip':
                icon = <EuiIcon type='indexPatternApp' style={{ margin: 10}}/>

                if (operationsCenterContext.eventDetails.destination) {
                    space1Heading = "Destination"
                    space1Content = operationsCenterContext.eventDetails.destination
                }
                

                if (operationsCenterContext.eventDetails.service) {
                    space2Heading = "Port"
                    space2Content = operationsCenterContext.eventDetails.service
                }

                space3Heading = "ASN"
                space3Content = operationsCenterContext.eventDetails.asn

                space4Heading = "Research"
                if (operationsCenterContext.eventDetails.anomaly === "Behaviour/Statistical"){
                    space4Content = <div>
                        <div>
                            <AsnAbnormalDetails 
                                asn={operationsCenterContext.eventDetails.asn}
                                host={operationsCenterContext.eventDetails.host}
                                timestamp={operationsCenterContext.eventDetails.timestamp}
                            />
                        </div>
                        <a 
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${KB_URL}/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(SourceAddress,DestinationAddress,DestinationPort,'ASN+Description',dst_geoip.country_code2),index:'${filebeatIndexId}',interval:auto,query:(language:lucene,query:'ASN%5C+Description:%22${operationsCenterContext.eventDetails.asn}%22+AND+SourceAddress:%22${operationsCenterContext.eventDetails.host}%22+AND+ML_models_triggered:%5B1+TO+*%5D'),sort:!('@timestamp',desc))`}
                        >

                        <button className="event-preview__btn event-preview__btn--success">
                            Show Logs
                        </button>

                        </a> 

                        <button onClick={handleAlertResolved} style={{marginLeft: 10}} className="event-preview__btn event-preview__btn--info">
                            Mark As Resolved
                        </button>

                    </div>
                } else if (operationsCenterContext.eventDetails.anomaly === "Threat Intelligence"){
                    space4Content = <div>
                        <button className="event-preview__btn event-preview__btn--success" onClick={handleResearch("xf")}>
                            X-Force
                        </button>
                        <button className="event-preview__btn event-preview__btn--success" onClick={handleResearch("otx")}>
                            OTX
                        </button>
                        <button className="event-preview__btn event-preview__btn--success" onClick={handleResearch("vt")}>
                            VirusTotal
                        </button>
                        <button className="event-preview__btn event-preview__btn--success" onClick={handleResearch("tc")}>
                            ThreatCrowd
                        </button>
                        <a 
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${KB_URL}/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(SourceAddress,DestinationAddress,DestinationPort,'ASN+Description',dst_geoip.country_code2,otx),index:'${filebeatIndexId}',interval:auto,query:(language:lucene,query:'SourceAddress:%22${operationsCenterContext.eventDetails.host}%22+AND+DestinationAddress:%22${operationsCenterContext.eventDetails.destination}%22+AND+otx:%22${operationsCenterContext.eventDetails.abnormality}%22'),sort:!('@timestamp',desc))`}
                        >

                        <button className="event-preview__btn event-preview__btn--success">
                            Show Logs
                        </button>

                        </a> 

                        <button onClick={handleAlertResolved} style={{marginLeft: 10}} className="event-preview__btn event-preview__btn--info">
                            Mark As Resolved
                        </button>

                    </div>
                }
                

                break;
            case 'win':
            case 'swift':
                icon = <EuiIcon type='logoWindows' style={{ margin: 10}}/>

                space1Heading = "Severity"
                space1Content = operationsCenterContext.eventDetails.severity

                let showLoginTimeLine = false
                if (operationsCenterContext.eventDetails.anomaly.toLowerCase() === "swift abnormal login time"){
                    showLoginTimeLine = true
                }


                space4Heading = "Details"
                space4Content = <Fragment>
                    {operationsCenterContext.eventDetails.log_msg ?
                        operationsCenterContext.eventDetails.log_msg.split(':::').map(logLine => <p>{logLine}</p>) 
                    : null 
                    }
                    {operationsCenterContext.eventDetails.links ?
                    <Fragment>
                        <a 
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${KB_URL}${operationsCenterContext.eventDetails.links}`}
                        >

                        <button className="event-preview__btn event-preview__btn--success">
                            Show Logs
                        </button>

                        </a> 
                        <button onClick={handleAlertResolved} style={{marginLeft: 10}} className="event-preview__btn event-preview__btn--info">
                            Mark As Resolved
                        </button>
                    </Fragment>
                    :
                        null
                    }
                    <br/>
                    { showLoginTimeLine ? <SwiftAbnormalLoginTimeline anomaly={operationsCenterContext.eventDetails.abnormality} /> : null }
                </Fragment>
                break;
            default:
                break;
        }

        changeEventDetails({
            'type': operationsCenterContext.eventDetails.type,
            'source': operationsCenterContext.eventDetails.host,
            'anomaly': operationsCenterContext.eventDetails.anomaly,
            'abnormality' : operationsCenterContext.eventDetails.abnormality,
            'icon': icon,
            'space1': {
                'heading': space1Heading,
                'content': space1Content
            },
            'space2': {
                'heading': space2Heading,
                'content': space2Content
            },
            'space3': {
                'heading': space3Heading,
                'content': space3Content
            },
            'space4': {
                'heading': space4Heading,
                'content': space4Content
            }
        })

    },[operationsCenterContext.eventDetails, filebeatIndexId])

    return <React.Fragment>
            { eventDetails && operationsCenterContext.eventDetails ? 
                    <React.Fragment>
                        <div className="content-view__header">
                            Event Details {eventDetails.icon}
                        </div>
                        <div className="event-details-place">
                            <ul className="event_details_blocks">
                                <li>
                                <div className="o_b">
                                    <div className="lbl">
                                        Host
                                    </div>
                                    <div className="vle">
                                        {operationsCenterContext.eventDetails.host}
                                    </div>
                                </div>
                                </li>
                                <li>
                                <div className="o_b">
                                    <div className="lbl">
                                        {eventDetails.space1.heading}
                                    </div>
                                    <div className="vle">
                                        {eventDetails.space1.content}
                                    </div>
                                </div>
                                </li>
                                <li>
                                <div className="o_b">
                                    <div className="lbl">
                                        {eventDetails.space2.heading}
                                    </div>
                                    <div className="vle">
                                        {eventDetails.space2.content}
                                    </div>
                                </div>
                                </li>
                                <li>
                                <div className="o_b">
                                    <div className="lbl">
                                        {eventDetails.space3.heading}
                                    </div>
                                    <div className="vle">
                                        {eventDetails.space3.content}
                                    </div>
                                </div>
                                </li>
                            </ul>

                            <ul className="annot_block">
                                <li>
                                    <div className="o_b">
                                        <div className="lbl">
                                            Anomaly Type
                                        </div>
                                        <div className="vle">
                                            {eventDetails.anomaly}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="o_b">
                                        <div className="lbl">
                                            {eventDetails.abnormality.startsWith("http") ? "Source(s)" : "Reason(s)"}
                                        </div>
                                        <div className="vle">
                                            {eventDetails.abnormality}
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <div>
                                <div className="lbl">
                                    {eventDetails.space4.heading}
                                </div>
                                <div className="vle">
                                    {eventDetails.space4.content}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>                    
                : 
                <div className="content-view__col--notselected" style={{paddingTop: 65}}> 
                    <div className="content-view__notselected-title">
                        Event Details
                    </div>
                    <div className="content-view__notselected-descr">
                        To view event details, please select a source from the left.
                    </div>
                </div>
            }
                
            </React.Fragment>

}

export default AlertDetails