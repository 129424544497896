import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import CONFIG from '../../../../config'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'

const AsnAbnormalDetails = (props) => {

    const [chartOptions, changeChartOptions] = useState(null)

    useEffect(()=>{

        const initialChartOptions = {
            title: {
                text: 'ASN Baselines',
                subtext: 'ASN Averages vs Current Host',
                textStyle: {
                    color: 'white'
                } 
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                data: ['Current Host', 'ASN'],
                textStyle: {
                    color: 'white'
                } 
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'log',
                axisLabel: {
                    color: 'white'
                } 
            },
            yAxis: {
                type: 'category',
                data: ['Average Connections', 'Average Bytes', 'Average Servers', 'Average Services'],
                axisLabel: {
                    color: 'white'
                } 
            },            
        };
        

        if (CONFIG.mode !== "DEMO"){

            const timestamp = Number(props.timestamp) ? moment(props.timestamp, 'x') : moment.utc(props.timestamp, "YYYY-MM-DDThh:mm:ss")

            const startTime = timestamp.unix() - (60*60)
            const endTime = timestamp.unix() + (60*60)

            Axios.post(CONFIG.api.asnAbnormalDetails, {
                's': props.host,
                'a': props.asn,
                'st': startTime,
                'et': endTime
            }).then(resp => changeChartOptions({
                ...initialChartOptions,
                series: [
                    {
                        name: 'Current Host',
                        type: 'bar',
                        data: [
                            resp.data[1]['avgConnections'], 
                            resp.data[1]['avgBytes'],
                            resp.data[1]['avgServices'],
                            resp.data[1]['avgServers']
                        ]
                    },
                    {
                        name: 'ASN',
                        type: 'bar',
                        data: [
                            resp.data[0]['avgConnections'], 
                            resp.data[0]['avgBytes'],
                            resp.data[0]['avgServices'],
                            resp.data[0]['avgServers']
                        ]
                    }
                ]
            }))
        } else {
            const sampleData = [
                {
                    "avgConnections": 9.575357406126637,
                    "avgBytes": 211467.0218040419,
                    "avgServices": 2.3205128205128205,
                    "avgServers": 2.1086010855241626
                },
                {
                    "avgConnections": 13.0,
                    "avgBytes": 13387.76924369748,
                    "avgServices": 2.0,
                    "avgServers": 3.75
                }
            ]
            changeChartOptions({
                ...initialChartOptions,
                series: [
                    {
                        name: 'ASN',
                        type: 'bar',
                        data: [
                            sampleData[0]['avgConnections'], 
                            sampleData[0]['avgBytes'],
                            sampleData[0]['avgServices'],
                            sampleData[0]['avgServers']
                        ]
                    },
                    {
                        name: 'Current Host',
                        type: 'bar',
                        data: [
                            sampleData[1]['avgConnections'], 
                            sampleData[1]['avgBytes'],
                            sampleData[1]['avgServices'],
                            sampleData[1]['avgServers']
                        ]
                    }
                ]
            })
        }
        

    },[props.host, props.timestamp, props.asn])

    return <div className="chart" style={{width: "100%", height: "100%"}}>
        { chartOptions ? <ReactEcharts option={chartOptions} />  : "Loading" }
    </div>
}

export default AsnAbnormalDetails