import Axios from 'axios';
import { ES_URL, KB_URL } from '../../../../../../config';
import React, { useState, useEffect, useContext } from 'react';
import {
  EuiText,
  EuiLink,
  EuiFlexItem,
  EuiCard
} from '@elastic/eui';
import KbIndexId from '../../../common/kbIndexId';
import cyContext from '../cyContext';

const EsUserDetails = (props) => {

  const context = useContext(cyContext);
  const TargetUser = props.entityID;
  console.log("Get User details");

  const [cardFooterContent, changeCardFooterContent] = useState();


  useEffect(()=>{

    KbIndexId('winlogbeat').then(data => {

      changeCardFooterContent(
        <div>
          <EuiText size="s">
            <p>
              <EuiLink
                href={`${KB_URL}/app/kibana#/discover/?_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'${data}',key:event_data.IpAddress,negate:!f,params:(query:'${context.rootEntity}',type:phrase),type:phrase,value:'${context.rootEntity}'),query:(match:(event_data.IpAddress:(query:'${context.rootEntity}',type:phrase))))),refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-24h,mode:quick,to:now))&_a=(columns:!(event_data.IpAddress,event_data.TargetUserName,event_data.LogonProcessName,event_data.AuthenticationPackageName,message),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'${data}',key:event_data.TargetUserName,negate:!f,params:(query:${TargetUser},type:phrase),type:phrase,value:${TargetUser}),query:(match:(event_data.TargetUserName:(query:${TargetUser},type:phrase))))),index:'${data}',interval:auto,query:(language:lucene,query:''),sort:!('@timestamp',desc))`}
              >
                    Raw Logs
              </EuiLink>
            </p>
          </EuiText>
        </div>
      );

    });


  },[TargetUser]);


  return (
    <EuiFlexItem key="CardAbnormalASN">

      <EuiCard
        title={`${TargetUser}`}
        betaBadgeLabel= 'User Login'
        description='User login recorded by Domain Controller'
        footer={cardFooterContent}
      />


    </EuiFlexItem>
  );

};

export default EsUserDetails;