import Axios from 'axios';
import { ES_URL, KB_URL } from '../../../../../../config';
import React, { useState, useEffect, useContext } from 'react';
import {
  EuiText,
  EuiLink,
  EuiFlexItem,
  EuiCard,
  EuiHorizontalRule
} from '@elastic/eui';
import KbIndexId from '../../../common/kbIndexId';
import cyContext from '../cyContext';
import moment from 'moment';

const EsClientAbnormalDetails = (props) => {

  const context = useContext(cyContext);
  const IPAddress = props.entityID;
  console.log('Get Abnormal ASN details');

  const [entityDetails, changeEntityDetails] = useState();
  const [indexID, changeIndexID] = useState();
  const [cardFooterContent, changeCardFooterContent] = useState();

  const startDate = moment.utc(context.startDate).valueOf();
  const startDateString = moment.utc(context.startDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  const endDate = moment.utc(context.endDate).valueOf();
  const endDateString = moment.utc(context.endDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  const query = {
    'size': 0,
    '_source': {
      'excludes': []
    },
    'aggs': {
      'Anomalies': {
        'terms': {
          'field': 'Abnormal_Notes',
          'size': 25
        }
      }
    },
    'query': {
      'bool': {
        'must': [
          {
            'match_all': {}
          },
          {
            'range': {
              '@timestamp': {
                'gte': startDate,
                'lte': endDate,
                'format': 'epoch_millis'
              }
            }
          },
          {
            'match_phrase': {
              'SourceAddress': {
                'query': IPAddress
              }
            }
          },
          {
            'match_phrase': {
              'DestinationAddress': {
                'query': context.rootEntity
              }
            }
          }
        ]
      }
    }
  };

  useEffect(()=>{

    KbIndexId('filebeat').then(data => {

      changeCardFooterContent(
        <div>
          <EuiText size="s">
            <p>
              <EuiLink
                target="_blank"
                href={`${KB_URL}/app/kibana#/discover/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${startDateString}',to:'${endDateString}'))&_a=(columns:!(SourceAddress,DestinationAddress,DestinationPort,'ASN+Description',Abnormal_Notes),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'${data}',key:DestinationAddress,negate:!f,params:(query:'${context.rootEntity}'),type:phrase,value:'${context.rootEntity}'),query:(match:(DestinationAddress:(query:'${context.rootEntity}',type:phrase)))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'${data}',key:'SourceAddress',negate:!f,params:(query:'${IPAddress}'),type:phrase,value:'${IPAddress}'),query:(match:('SourceAddress':(query:'${IPAddress}',type:phrase)))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'${data}',key:query,negate:!f,type:custom,value:'%7B%22bool%22:%7B%22filter%22:%7B%22script%22:%7B%22script%22:%7B%22source%22:%22doc%5B!'Abnormal_Notes!'%5D.length+%3E+0%22,%22lang%22:%22painless%22%7D%7D%7D%7D%7D'),query:(bool:(filter:(script:(script:(lang:painless,source:'doc%5B!'Abnormal_Notes!'%5D.length+%3E+0'))))))),index:'${data}',interval:auto,query:(language:lucene,query:''),sort:!('@timestamp',desc))`}
              >
                Raw Logs
              </EuiLink>
            </p>
          </EuiText>
        </div>
      );
      changeIndexID(data);

    });

    Axios.post(`${ES_URL}/filebeat-*/_search`, query).then(resp => {
      const buckets = resp.data.aggregations.Anomalies.buckets;
      const details = {
        'Name': IPAddress,
        'Anomalies': []
      };
      buckets.map(bucket => {
        const anomaly = bucket.key;
        // details.Anomalies += `${anomaly}\n`;
        details.Anomalies.push(<EuiHorizontalRule size="half" margin="s"/>);
        details.Anomalies.push(<span>{anomaly}</span>);
      });
      changeEntityDetails(details);
    });

  }, [IPAddress]);


  return (
    <EuiFlexItem key="CardAbnormalASN">
      {entityDetails ?
        <EuiCard
          title={`${entityDetails.Name}`}
          betaBadgeLabel="Anomalies"
          description={entityDetails.Anomalies}
          footer={cardFooterContent}
        />
        :
        null }
    </EuiFlexItem>);


};

export default EsClientAbnormalDetails;
