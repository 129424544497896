import React, { useEffect, useState, useLayoutEffect } from 'react'
import CONFIG, { KB_URL } from '../../config'
import Axios from 'axios'

const Analytics = () => {

    const [iframeHeight, changeIframeHeight] = useState(window.innerHeight)

    useLayoutEffect(()=>{
        document.querySelector("#mainContent > section.content-header").hidden=true;
        const headerHeight = document.querySelector("#mainContent > section.content-header").scrollHeight + document.querySelector("#cs_root > div > header").scrollHeight
        changeIframeHeight(window.innerHeight - headerHeight)
    },[])

    useEffect(()=>{
        let curUrl = "";
        setInterval(() => {
            if (document.querySelector("#mainContent > section.content > iframe")){
                if (document.querySelector("#mainContent > section.content > iframe").contentWindow.location.href !== curUrl){
                    curUrl = document.querySelector("#mainContent > section.content > iframe").contentWindow.location.href
                    Axios.get(CONFIG.api.heartbeat);
                }
            }
        }, 1000);
    },[])

    return <iframe 
        title={"CyberSift Analytics"}
        src={`${KB_URL}/app/kibana#/discover`}
        height={iframeHeight-40}
        width="100%" 
    />

}

export default Analytics