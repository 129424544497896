import React, {useState} from 'react'
import RiskCategoryBreakdown from './widgets/ip/riskCategoryBreakdown'
import TopSuspiciousHosts from './widgets/ip/topSuspiciousHosts'
import OffenceLocations from './widgets/ip/offenceLocations'
import EntityExplorer from './widgets/ip/entityExplorer/components/Main'

const Network = () => {

  const [entityExplorerRoot, changeEntityExplorerRoot] = useState(null);

  const handleSuspiciousHostClick = (ipAddress) => {
    changeEntityExplorerRoot({
      id: ipAddress,
      type: 'AnomalousIP'
    })
  }

    return <React.Fragment>
        <div className="content__top-row" style={{height: "436px"}}>
            <div className="col-lg-4 col-md-6 col-12" style={{height: "100%"}}>
              <RiskCategoryBreakdown />
            </div>

            <div className="col-lg-4 col-md-6 col-12" style={{height: "100%"}}>
              <TopSuspiciousHosts handleClick={handleSuspiciousHostClick}/>
            </div>

            <div className="col-lg-4 col-md-6 col-12" style={{height: "100%"}}>
              <OffenceLocations />
            </div>
          </div>

          <div className="content__main-row">
            <div className="host-view">
              <div className="host-view__header">
                Entity Explorer
              </div>

              <div className="host-view__body">
                <EntityExplorer rootEntity={entityExplorerRoot} />
                {/* <div className="host-view__graph-place">             
                  <div className="host-view__graph-canvas">
                    <div className="host-view__graph-circle host-view__graph-circle-1"></div>
                    <div className="host-view__graph-circle host-view__graph-circle-2"></div>
                    <div className="host-view__graph-circle host-view__graph-circle-3"></div>
                    <div className="host-view__graph-circle host-view__graph-circle-4"></div>
                    <img className="host-view__graph-arrow host-view__graph-arrow-1" src="images/graph-arrow-1.svg" alt="" />
                    <img className="host-view__graph-arrow host-view__graph-arrow-2" src="images/graph-arrow-2.svg" alt="" />
                    <img className="host-view__graph-arrow host-view__graph-arrow-3" src="images/graph-arrow-3.svg" alt="" />
                    <div className="host-view__graph-title host-view__graph-title-1">MICROSOFT-CORP-MSN-AS-BLOCK - Microsoft Corporation</div>
                    <div className="host-view__graph-title host-view__graph-title-2">AKAMAI - Akamai Technologies</div>
                    <div className="host-view__graph-title host-view__graph-title-3">192.168.80.186</div>
                  </div>
                </div>

                <div className="host-view__controls">
                  <form>
                    <div className="checks_wrap">
                      <label htmlFor="ibm_force">
                        <input
                          name="ibm_force"
                          id="ibm_force"
                          type="checkbox"
                        />
                        IBM XForce
                      </label>
                      <label htmlFor="otx">
                        <input name="otx" id="otx" type="checkbox" />
                        OTX
                      </label>
                      <label htmlFor="virus">
                        <input name="virus" id="virus" type="checkbox" />
                        Virus Total
                      </label>
                      <label htmlFor="other">
                        <input name="other" id="other" type="checkbox" />
                        Other Threat Field
                      </label>
                    </div>

                    <div className="btns_wrap">
                      <a href="/#" className="btn black">Research</a>
                      <input
                        type="submit"
                        className="btn white"
                        value="Create/Add To Existing Case"
                      />
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
    </React.Fragment>

}

export default Network