import Axios from 'axios';
import { KB_API_URL } from '../../../../config';

const KbIndexId = async (searchTerm) => {

  const response = await Axios.get(`${KB_API_URL}/api/saved_objects/_find?type=index-pattern&fields=title&search=${searchTerm}*`);
  return response.data.saved_objects[0] ? response.data.saved_objects[0].id : null;

};

export default KbIndexId;
