/* eslint-disable new-cap */
import React, { useState, useRef, Fragment, useContext, useEffect } from 'react';
import cytoscape from 'cytoscape';
import EsTopAsn from '../data/esTopAsn';
import EsTopAsnAnomalous from '../data/esTopAsnAbnormal';
import cyContext from '../cyContext';
import EsUserLogins from '../data/esUserLogins';
import {
  EuiButton
} from '@elastic/eui';
import EsTopIpAnomalous from '../data/esTopIpAbnormal';
import moment from 'moment';
import RootNodeModal from './RootNodeModal';
import EsTopWindows from '../data/esTopWindows';
import Axios from 'axios';
import LoadingModal from './LoadingModal';
import { API_URL } from '../../../../../../config';
import EsAsnIp from '../data/esAsnIP';
import EsTopClientsAbnormal from '../data/esTopClientsAbnormal';

// color palette:
// https://coolors.co/app/264653-2a9d8f-e9c46a-f4a261-e76f51

const CyGraph = (props) => {

  const initialData = useRef([]);

  const [initialRun, changeIntialRun] = useState(true);
  const [showRootIpModal, changeShowRootIpModal] = useState(false);
  const [showLoadingModal, changeShowLoadingModal] = useState(false);
  
  const context = useContext(cyContext);

  const dateInfo = useRef([]);
  dateInfo.current.startDate = moment.utc(context.startDate).valueOf();
  dateInfo.current.endDate = moment.utc(context.endDate).valueOf();

  const cy = useRef();
  const elementsRef = useRef([]);
  const rootEntity = useRef()

  const getElements = () => {
    console.log('Get elements...');
    return elementsRef.current;
  };

  const cyLayout = {
    name: 'cose',
    idealEdgeLength: 100,
    nodeOverlap: 40,
    refresh: 20,
    fit: true,
    padding: 30,
    randomize: false,
    componentSpacing: 100,
    nodeRepulsion: 500000,
    edgeElasticity: 100,
    nestingFactor: 5,
    gravity: 80,
    numIter: 1000,
    initialTemp: 200,
    coolingFactor: 0.95,
    minTemp: 1.0
  };

  const handleSetElements = (elements) => {
    console.log("updating elements ", elements);
    elementsRef.current = [...elements];

    cy.current.json({ elements: elements });
    
    cy.current.nodes().on('click', function (e) {
      const clickedNodeData = e.target.data();

      context.handleEntityChange(clickedNodeData);
      console.log('node clicked', clickedNodeData);
    });

    cy.current.layout(cyLayout).run();
  };

  const initGraph = () => {
    if (initialData.current.length === 0) {
      changeShowRootIpModal(true);
      return;
    }

    cy.current = cytoscape({
      container: document.getElementById('cy'), // container to render in
      zoom: 5,
      elements: elementsRef.current,
      style: [
        {
          selector: 'node',
          style: {
            'background-color': '#77C0B7',
            'label': 'data(id)',
            'color': 'white'
          }
        },
        {
          selector: 'node[root]',
          style: {
            'background-color': '#2A9D8F',
            'label': 'data(id)',
            'color': 'white'
          }
        },
        {
          selector: 'node[size]',
          style: {
            'background-color': '#77C0B7',
            'label': 'data(id)',
            'width': 'data(size)',
            'height': 'data(size)',
            'color': 'white'
          }
        },
        {
          selector: 'node[size][abnormal]',
          style: {
            'background-color': '#E76F51',
            'label': 'data(id)',
            'width': 'data(size)',
            'height': 'data(size)',
            'color': 'white'
          }
        },
        {
          selector: 'node[size][entityType = "AnomalousIP"]',
          style: {
            'background-color': '#F4A261',
            'label': 'data(id)',
            'width': 'data(size)',
            'height': 'data(size)',
            'color': 'white'
          }
        },
        {
          selector: 'node[size][entityType = "winlog.event_data.Image"]',
          style: {
            'background-color': '#77C0B7',
            'label': 'data(id)',
            'width': 'data(size)',
            'height': 'data(size)',
            'color': 'white'
          }
        },
        {
          selector: 'node[size][entityType = "winlog.event_data.QueryName"]',
          style: {
            'background-color': '#264653',
            'label': 'data(id)',
            'width': 'data(size)',
            'height': 'data(size)',
            'color': 'white'
          }
        },
        {
          selector: 'node[size][user]',
          style: {
            'background-color': '#1B645C',
            'label': 'data(id)',
            'width': 'data(size)',
            'height': 'data(size)',
            'color': 'white'
          }
        },
        {
          selector: 'edge',
          style: {
            'width': 1,
            'line-color': '#77C0B7',
            'target-arrow-color': '#77C0B7',
            'target-arrow-shape': 'triangle',
            'curve-style': 'unbundled-bezier',
            'control-point-weights': 0.5,
            'control-point-distances': 25
          }
        },
        {
          selector: 'edge[abnormal]',
          style: {
            'width': 1,
            'line-color': '#E76F51',
            'target-arrow-color': '#E76F51',
            'target-arrow-shape': 'triangle',
            'curve-style': 'unbundled-bezier',
            'control-point-weights': 0.5,
            'control-point-distances': 25
          }
        },
        {
          selector: 'edge[entityType = "AnomalousIP"]',
          style: {
            'width': 1,
            'line-color': '#F4A261',
            'target-arrow-color': '#F4A261',
            'target-arrow-shape': 'triangle',
            'curve-style': 'unbundled-bezier',
            'control-point-weights': 0.5,
            'control-point-distances': 25
          }
        },
        {
          selector: 'edge[entityType = "winlog.event_data.Image"]',
          style: {
            'width': 1,
            'line-color': '#77C0B7',
            'target-arrow-color': '#77C0B7',
            'target-arrow-shape': 'triangle',
            'curve-style': 'unbundled-bezier',
            'control-point-weights': 0.5,
            'control-point-distances': 25
          }
        },
        {
          selector: 'edge[entityType = "winlog.event_data.QueryName"]',
          style: {
            'width': 1,
            'line-color': '#264653',
            'target-arrow-color': '#264653',
            'target-arrow-shape': 'triangle',
            'curve-style': 'unbundled-bezier',
            'control-point-weights': 0.5,
            'control-point-distances': 25
          }
        },
        {
          selector: 'edge[user]',
          style: {
            'width': 1,
            'line-color': '#1B645C',
            'target-arrow-color': '#1B645C',
            'target-arrow-shape': 'triangle',
            'curve-style': 'unbundled-bezier',
            'control-point-weights': 0.5,
            'control-point-distances': 25
          }
        }
      ],

      layout: cyLayout

    });


    cy.current.cxtmenu({
      selector: 'node[entityType="ip_address"]',
      commands: [
        {
          content: 'Top ASNs',
          select: function (ele) {
            EsTopAsn('SourceAddress', ele.data('id'), getElements, handleSetElements, dateInfo.current.startDate, dateInfo.current.endDate);
          }
        },
        {
          content: 'Anomalous ASNs',
          select: function (ele) {
            EsTopAsnAnomalous('SourceAddress', ele.data('id'), getElements, handleSetElements, dateInfo.current.startDate, dateInfo.current.endDate);
          }
        }
        // {
        //   content: 'Users',
        //   select: function (ele) {
        //     EsUserLogins(ele.data('id'), getElements, handleSetElements, dateInfo.current.startDate, dateInfo.current.endDate);
        //   }
        // }
      ]
    });


    cy.current.cxtmenu({
      selector: 'node[entityType="NormalASN"]',
      commands: [
        {
          content: 'IP Addresses',
          select: function (ele) {
            EsAsnIp('ASN Description', ele.data('id'), getElements, handleSetElements, dateInfo.current.startDate, dateInfo.current.endDate, rootEntity.current);
          }
        }
      ]
    });


    cy.current.cxtmenu({
      selector: 'node[entityType="AnomalousASN"]',
      commands: [
        {
          content: 'IP Addresses',
          select: function (ele) {
            EsAsnIp('ASN Description', ele.data('id'), getElements, handleSetElements, dateInfo.current.startDate, dateInfo.current.endDate, rootEntity.current);
          }
        }
      ]
    });
    

    cy.current.cxtmenu({
      selector: 'node[entityType="win_computername"]',
      commands: [
        {
          content: 'Top Processes',
          select: function (ele) {
            EsTopWindows('winlog.computer_name', ele.data('id'),
              'winlog.event_data.Image', getElements, handleSetElements, dateInfo.current.startDate, dateInfo.current.endDate);
          }
        },
        {
          content: 'Top DNS',
          select: function (ele) {
            EsTopWindows('winlog.computer_name', ele.data('id'),
              'winlog.event_data.QueryName', getElements, handleSetElements, dateInfo.current.startDate, dateInfo.current.endDate);
          }
        },
        {
          content: 'System Events',
          select: function (ele) {
            changeShowLoadingModal(true);
            Axios.post(`${API_URL}/visuals/windows_event_log`, {
              start_time: dateInfo.current.startDate,
              end_time: dateInfo.current.endDate,
              log_type: 'System',
              computer_name: ele.data('id'),
              w: window.innerWidth,
              h: window.innerHeight
            }, {
              withCredentials: true
            }).then(resp => {
              window.Bokeh.embed.embed_item(resp.data);
              document.getElementById('bokehplot').removeAttribute('hidden');
              document.getElementById('cy').setAttribute('hidden', 'true');
              changeShowLoadingModal(false);
            }).catch(e => {
              alert('Could not load visualization!')
              console.log(e)
              changeShowLoadingModal(false);
          });
          }
        }
      ]
    });

    cy.current.cxtmenu({
      selector: 'node[entityType = "AnomalousIP"]',
      commands: [
        {
          content: 'ASN Info',
          select: function (ele) {
            EsTopAsnAnomalous('DestinationAddress', ele.data('id'), getElements, handleSetElements, dateInfo.current.startDate, dateInfo.current.endDate);
          }
        },
        {
          content: 'Abnormal Clients',
          select: function (ele) {
            EsTopClientsAbnormal('DestinationAddress', ele.data('id'), getElements, handleSetElements, dateInfo.current.startDate, dateInfo.current.endDate);
          }
        }
      ]
    });

    cy.current.cxtmenu({
      selector: 'core',
      commands: [
        {
          content: 'Reset',
          select: function () {
            handleSetElements(initialData.current);
            context.changeRootEntity(initialData.current[0].data.id);
          }
        },
        {
          content: 'IP Anomalies',
          select: function () {
            const anomaliesData = [{
              data: {
                id: 'IP_Anomalies', root: true
              }
            }];
            EsTopIpAnomalous(anomaliesData, handleSetElements, dateInfo.current.startDate, dateInfo.current.endDate);
          }
        }
      ]
    });

    cy.current.nodes().on('click', function (e) {
      const clickedNodeData = e.target.data();

      context.handleEntityChange(clickedNodeData);
      console.log('node clicked', clickedNodeData);
    });

    changeIntialRun(false);

  };


  const changeRootEntity = (val, entityType, init = false) => {
    initialData.current = [{
      data: { id: val, root: true, entityType: entityType }
    }];
    elementsRef.current = [...initialData.current];
    context.changeRootEntity(val);
    rootEntity.current = val;
    if (init) {
      initGraph();
    }
  };

  useEffect(()=>{
    console.log("got root entity", props.rootEntity)
    if (props.rootEntity){
      changeRootEntity(props.rootEntity.id, props.rootEntity.type, true)
    }    
  },[props.rootEntity])


  return (
    <Fragment>
      { initialRun ?
        <EuiButton fill onClick={initGraph}>
          Open Graph
        </EuiButton>
        :
        null
      }

      <LoadingModal isModalVisible={showLoadingModal}/>
      <RootNodeModal isModalVisible={showRootIpModal} handleIpChange={changeRootEntity}/>

      <div id="bokehplot" style={{ height: '85vh', width: '90vw' }} hidden />
      <div id="cy" style={{ height: '85vh', width: '100%' }} />

    </Fragment>);


};

export default CyGraph;
