import Axios from 'axios';
import { ES_URL } from '../../../../../../config';

const EsTopIpAnomalous = (elements, changeElements, startDate, endDate) => {

  const query = {
    'size': 0,
    '_source': {
      'excludes': []
    },
    'aggs': {
      'Abnormal': {
        'terms': {
          'field': 'Abnormal_Notes',
          'size': 20
        }
      }
    },
    'query': {
      'bool': {
        'must': [
          {
            'match_all': {}
          },
          {
            'range': {
              '@timestamp': {
                'gte': startDate,
                'lte': endDate,
                'format': 'epoch_millis'
              }
            }
          }
        ]
      }
    }
  };


  Axios.post(`${ES_URL}/filebeat-*/_search`, query).then(resp => {
    const buckets = resp.data.aggregations.Abnormal.buckets;
    buckets.map(bucket => {
      elements.push(
        {
          data: { id: bucket.key, size: Math.log10(bucket.doc_count+1) * 10, entityType: 'AnomalousIP' }
        }
      );
      elements.push(
        {
          data: { id: `IP_Anomalies:${bucket.key}`, source: 'IP_Anomalies', target: bucket.key, entityType: 'AnomalousIP' }
        }
      );
    });
    changeElements(elements);
  });


};

export default EsTopIpAnomalous;
