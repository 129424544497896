import React, {useState} from 'react'
import Axios from 'axios'
import CONFIG from '../../config'
import { EuiAccordion } from '@elastic/eui'

const Login = (props) => {

    const [username, changeUsername] = useState()
    const [password, changePassword] = useState()
    const [otp, changeOTP] = useState("")

    const handleLogin = () => {
        Axios.post(CONFIG.api.login, {
            u: username,
            p: password, 
            o: otp
          }).then( resp => {
              localStorage.setItem('loggedIn', true) 
              props.handleSuccessfulLogin(username)
              window.$("body").removeClass('sidebar-collapse');
          })
            .catch( _ => window.alert("Login Failed!"))
    }

    

    return <div className="content-view__col--notselected">
            <div>
                <div className="form-group">
                    <label for="username">Username</label>
                    <input type="text" className="form-control" id="username" placeholder="Enter username" onChange={(e)=>changeUsername(e.target.value)}/>
                </div>
                <div className="form-group">
                    <label for="password">Password</label>
                    <input type="password" className="form-control" id="password" placeholder="Password" onChange={(e)=>changePassword(e.target.value)}/>
                </div>
                <div className="form-group">
                    <EuiAccordion
                        id="otp-accordian"
                        buttonContent="2FA code"
                    >
                        <input type="text" className="form-control" id="2FACode" placeholder="Enter 2FA code" onChange={(e)=>changeOTP(e.target.value)}/> 
                    </EuiAccordion>
                </div>
                <div style={{textAlign:"center"}}>
                    <button className="btn btn-primary" onClick={handleLogin}>Submit</button>
                </div>   
            </div>
        </div>
}

export default Login