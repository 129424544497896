import Axios from 'axios';
import { ES_URL } from '../../../../../../config';

const EsAsnIp = (SearchField, SearchValue, getElements, changeElements, startTime, endTime, sourceEntity) => {

  const elements = getElements();
  console.log("esAsnIP: ", sourceEntity);

  const query = {
    'size': 0,
    '_source': {
      'excludes': []
    },
    'aggs': {
      'ASN': {
        'terms': {
          'field': 'DestinationAddress',
          'size': 25
        }
      }
    },
    'query': {
      'bool': {
        'must': [
          {
            'match_all': {}
          },
          {
            'range': {
              '@timestamp': {
                'gte': startTime,
                'lte': endTime,
                'format': 'epoch_millis'
              }
            }
          },
          {
            'match_phrase': {
              [SearchField]: {
                'query': SearchValue
              }
            }
          },
          {
            'match_phrase': {
              'SourceAddress': {
                'query': sourceEntity
              }
            }
          }
        ]
      }
    }
  };

  Axios.post(`${ES_URL}/filebeat-*/_search`, query).then(resp => {
    const buckets = resp.data.aggregations.ASN.buckets;
    buckets.map(bucket => {
      elements.push(
        {
          data: { id: bucket.key, size: Math.log10(bucket.doc_count+1) * 10, entityType: 'ip_address_leaf' }
        }
      );
      elements.push(
        {
          data: { id: `${SearchValue}:${bucket.key}`, source: SearchValue, target: bucket.key }
        }
      );
    });
    changeElements(elements);
  });


};

export default EsAsnIp;
