import React, {useState, useEffect} from 'react'
import CONFIG from '../../../../config'
import Axios from 'axios'
import WidgetScaffold from '../widgetScaffold';

const SystemElements = () => {

    const [content, changeContent] = useState(null)

    useEffect(()=>{
        switch (CONFIG.mode) {
            case 'PROD':
            case 'DEV':
                Axios.get(CONFIG.api.systemStatus)
                    .then(resp => {
                        const newContent = resp.data.map(elem => <li className={elem.ok ? 'stat__iconed-item stat__iconed-item--success' : 'stat__iconed-item stat__iconed-item--warning'}>
                            {elem.name}
                        </li>)
                        changeContent(<ul className="stat__list">{newContent}</ul>)
                    })
                    .catch(err=>console.log(err))
                break;
        
            default:
                changeContent(<ul className="stat__list">
                    <li className={`stat__iconed-item stat__iconed-item--warning`}>
                        IP Collection
                    </li>
                    <li className={`stat__iconed-item stat__iconed-item--success`}>
                        DNS Collection
                    </li>
                    <li className={`stat__iconed-item stat__iconed-item--success`}>
                        Windows Log Collection
                    </li>
                </ul>)
                break;
        } 
    },[])

    return <WidgetScaffold
        configureableLayout={true} 
        title="System Elements"
        titleClickHandler={null}
        content={content}
        style={{
            marginLeft: "auto",
            marginRight: "auto",
            overflowY: "auto",
            paddingRight: "50%"
        }}
    />
        
}

SystemElements.displayName = "SystemElements"

export default SystemElements