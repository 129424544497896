import React from 'react'
import BeatLoader from "react-spinners/BeatLoader";
import ConfigureLayout from './configuration/configureLayout';


const WidgetScaffold = (props) => {
    return <div className="stat" style={{height: '100%'}}>
        {props.configureableLayout ? <ConfigureLayout /> : null}
        <div className="stat__title">
            { props.titleClickHandler ? <a href="/#" onClick={props.titleClickHandler}>
                    {props.title}
                </a>
            :
                props.title}
            
        </div>
        <div
            className="stat__list-place"
            style={ props.childStyle ? props.childStyle : {
                marginTop: 10,
                marginLeft: props.content ? null: "auto",
                marginRight: props.content ? null: "auto"
            }}
        >
            {props.content ? 
                props.content
            : 
                <BeatLoader
                    loading={true}
                    color="#36D7B7"
                /> 
            }
        </div> 
                                                    
    </div>
}

export default WidgetScaffold