import React, {useState, useEffect} from 'react'
import CONFIG, { KB_URL } from '../../../../config'
import Axios from 'axios'
import KbIndexId from '../common/kbIndexId';
import WidgetScaffold from '../widgetScaffold';

const TopKnownThreats = () => {

    const [content, changeContent] = useState(null)
    const [alertsIndexId, changeAlertsIndexId] = useState(null)

    useEffect(()=>{
        KbIndexId('alerts').then(data => data ? changeAlertsIndexId(data) : changeContent(<ul className="stat__list">
            No Data
        </ul>))
    },[])

    const handleExpansion = () => {
        switch (CONFIG.mode) {
            case 'PROD':
            case 'DEV':
                window.open(`${KB_URL}/app/kibana#/visualize/create?type=table&indexPattern=${alertsIndexId}&_g=(refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(filters:!(),linked:!f,query:(language:lucene,query:'*'),uiState:(vis:(params:(sort:(columnIndex:!n,direction:!n)))),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'4',params:(field:severity,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,row:!t,size:5),schema:split,type:terms),(enabled:!t,id:'3',params:(field:data.threats,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:50),schema:bucket,type:terms)),params:(dimensions:(buckets:!((accessor:1,aggType:terms,format:(id:terms,params:(id:string,missingBucketLabel:Missing,otherBucketLabel:Other)),params:())),metrics:!((accessor:2,aggType:count,format:(id:number),params:())),splitRow:!((accessor:0,aggType:terms,format:(id:terms,params:(id:string,missingBucketLabel:Missing,otherBucketLabel:Other)),params:()))),perPage:10,showMetricsAtAllLevels:!f,showPartialRows:!f,showTotal:!f,sort:(columnIndex:!n,direction:!n),totalFunc:sum),title:'',type:table))`, "_blank")
                break;
        
            default:
                break;
        }
    }

    useEffect(()=>{
        switch (CONFIG.mode) {
            case 'PROD':
            case 'DEV':
                Axios.get(CONFIG.api.topKnownThreats)
                    .then(resp => {
                        let formattedContent = resp.data.map(elem => {
                            let icon

                            switch (elem['threat_rating']) {
                                case 1:
                                    icon = "success"
                                    break
                                case 2:
                                    icon = "warning"
                                    break
                                case 3:
                                    icon = "danger"
                                    break
                                default:
                                    icon = "success"
                                    break
                            }
                            return <li key={elem.threat} className={`stat__iconed-item stat__iconed-item--${icon}`}>
                                    <a 
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`${KB_URL}/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!(severity,host,data.destination,title,details,data.threats),index:'${alertsIndexId}',interval:auto,query:(language:lucene,query:'data.threats:%22${elem.threat}%22'),sort:!('@timestamp',desc))`}
                                    >
                                        {elem.threat}
                                    </a>                                        
                                </li>
                        })
                        changeContent(<ul className="stat__list">
                            {formattedContent}
                        </ul>)
                    })
                    .catch(err => {
                        console.log(err)
                        changeContent(<ul className="stat__list">
                            No Data
                        </ul>)
                    })
                break;
        
            default:
                changeContent(<ul className="stat__list">
                    <li className="stat__iconed-item stat__iconed-item--success">
                        Malware.IP
                    </li>
                    <li className="stat__iconed-item stat__iconed-item--success">
                        Scanning Host
                    </li>
                    <li className="stat__iconed-item stat__iconed-item--success">
                        Malware Domain
                    </li>
                    <li className="stat__iconed-item stat__iconed-item--warning">
                        Tor Exit Node
                    </li>
                </ul>)
                break;
        }
    },[alertsIndexId])

    return <WidgetScaffold
            titleClickHandler={handleExpansion}
            configureableLayout={true} 
            title="Top Known Threats"
            content={content}
        />
    
}

TopKnownThreats.displayName = "TopKnownThreats"

export default TopKnownThreats