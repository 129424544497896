import React, { Fragment, useEffect, useState } from 'react'
import { EuiFormRow, EuiFieldText, EuiButton, EuiFieldPassword, EuiForm  } from '@elastic/eui'
import CONFIG from '../../../../config'
import Axios from 'axios'


const ExternalIntegrations = () => {

    const [s1Setting, changeS1Setting] = useState({
        'endpoint': null,
        'token': null
    })

    useEffect(()=>{

        if (CONFIG.mode !== "DEMO"){
            Axios.get(CONFIG.api.getS1Settings).then(resp => {
                if (resp.data){
                    changeS1Setting({...s1Setting, ...resp.data})    
                }
            })
        }

    },[])

    const handleS1ConfigChange = () => {
        if (CONFIG.mode !== "DEMO"){
            Axios.post(CONFIG.api.setS1Settings, s1Setting).then( _ => alert("SentinelOne Settings Changed"))
            .catch( err => {
                console.log(err)
                alert("Could not change SentinelOne settings")
            })
        }
    }

    return  <Fragment>
                <EuiForm>
                    <EuiFormRow>
                        <h3>
                            SentinelOne Settings
                        </h3>
                    </EuiFormRow>
                    <EuiFormRow label="Endpoint URL" helpText="Usually the same URL as that used to login to dashboard">
                        <EuiFieldText
                            placeholder="Endpoint URL"
                            value={s1Setting.endpoint}
                            onChange={(e) => changeS1Setting({...s1Setting, endpoint: e.target.value})}
                            aria-label="Endpoint URL"
                        />        
                    </EuiFormRow>
                    <br/>
                    <EuiFormRow label="API Token" helpText="Not shown for security purposes">
                        <EuiFieldPassword
                            placeholder="API Token"                    
                            value={s1Setting.token}
                            onChange={(e) => changeS1Setting({...s1Setting, token: e.target.value})}
                            aria-label="API Token"
                        />        
                    </EuiFormRow>
                    <br/>
                    <EuiButton 
                        style={{marginTop: 22, marginLeft: 15}}
                        color="primary" 
                        fill={true}
                        onClick={handleS1ConfigChange}
                    >
                        Submit
                    </EuiButton>
                </EuiForm>
            </Fragment>

}

export default ExternalIntegrations

