import React, {useState, useEffect} from 'react';
import {
  EuiFlexItem,
  EuiCard,
} from '@elastic/eui';
import { EuiButton } from '@elastic/eui';
const ResearchIP = (props) => {

  const [ipAddress, changeIpAddress] = useState(props.entityID);
  useEffect( ()=>changeIpAddress(props.entityID), [props.entityID])

  const handleClick = (provider) => () => {
    switch (provider) {
      case 'vt':
        window.open(`https://www.virustotal.com/graph/${ipAddress}`, '_blank');
        break;
      case 'otx':
        window.open(`https://otx.alienvault.com/indicator/ip/${ipAddress}`, '_blank');
        break;    
      case 'xf':
        window.open(`https://exchange.xforce.ibmcloud.com/search/${ipAddress}`, '_blank');
        break;
      case 'tc':
        window.open(`https://www.threatcrowd.org/ip.php?ip=${ipAddress}`, '_blank');
        break;
      default:
        break;
    }
  }

  return (
    <EuiFlexItem key="CardResearchIP">
      
        <EuiCard
          title={`Threat Intelligence`}
          betaBadgeLabel="Research"
          description={<React.Fragment>
            <EuiButton fill size="s" style={{ margin:10 }} onClick={handleClick('vt')}> VirusTotal </EuiButton>
            <br />
            <EuiButton fill size="s" style={{ margin:10 }} onClick={handleClick('otx')}> OTX </EuiButton>
            <br />
            <EuiButton fill size="s" style={{ margin:10 }} onClick={handleClick('xf')}> XForce </EuiButton>
            <br />
            <EuiButton fill size="s" style={{ margin:10 }} onClick={handleClick('tc')}> ThreatCrowd </EuiButton>
          </React.Fragment>}
        />
      
    </EuiFlexItem>);


};

export default ResearchIP;
