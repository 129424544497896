import React, { Fragment, useState, useEffect } from 'react'
import { EuiStepsHorizontal } from '@elastic/eui';
import { EuiPanel } from '@elastic/eui';
import { EuiIcon } from '@elastic/eui';

const backgroundString = "rgba(37, 38, 46, 0.5)"

const DeployAgents = () => {

    const [currentStep, changeCurrentStep] = useState(0)
    const [steps, changeSteps] = useState([
        {
            id: 0,
            title: 'Select Log Source',
            isSelected: true,
            onClick: () => changeCurrentStep(0),
        },
        {
            id: 1,
            title: 'Select Operating System & Version',
            onClick: () => changeCurrentStep(1),
        },
        {
            id: 2,
            title: 'Read More',
            onClick: () => changeCurrentStep(2),
        }
      ])

    useEffect(()=>{
        const newSteps = steps.map(step => {
            if (step.id < currentStep){
                return {
                    ...step,
                    isSelected: false,
                    isComplete: true
                }
            } else if (step.id === currentStep){
                return {
                    ...step,
                    isSelected: true,
                    isComplete: false
                }
            }

            return {
                ...step,
                isSelected: false,
                isComplete: false
            }

        })

        changeSteps(newSteps)
        
    },[currentStep])

    const [logSource, changeLogSource] = useState(null)
    const [downloadContent, changeDownloadContent] = useState(<EuiPanel
    >
        Please select a log source from step 1
    </EuiPanel>)

    useEffect(()=>{

        let newDownloadContent

        switch (logSource) {
            case 'file':
                newDownloadContent = <Fragment>
                    <EuiPanel style={{background: backgroundString}}
                        onClick={() => {
                            window.open("https://csinstallscripts.blob.core.windows.net/agents/filebeat-7.3.2-64bit.zip", "_blank")
                            changeCurrentStep(2)
                        }}>
                        Windows 64-bit >>
                    </EuiPanel>
                    <EuiPanel style={{background: backgroundString}}
                        onClick={() => {
                            window.open("https://csinstallscripts.blob.core.windows.net/agents/filebeat-7.3.2-32bit.zip", "_blank")
                            changeCurrentStep(2)
                        }}>
                        Windows 32-bit >>
                    </EuiPanel>
                    <EuiPanel style={{background: backgroundString}}
                        onClick={() => {
                            window.open("https://csinstallscripts.blob.core.windows.net/agents/filebeat-7.3.2-64bit.deb", "_blank")
                            changeCurrentStep(2)
                        }}>
                        Linux DEB 64-bit >>
                    </EuiPanel>
                    <EuiPanel style={{background: backgroundString}}
                        onClick={() => {
                            window.open("https://csinstallscripts.blob.core.windows.net/agents/filebeat-7.3.2-32bit.deb", "_blank")
                            changeCurrentStep(2)
                        }}>
                        Linux DEB 32-bit >>
                    </EuiPanel>
                    <EuiPanel style={{background: backgroundString}}
                        onClick={() => {
                            window.open("https://csinstallscripts.blob.core.windows.net/agents/filebeat-7.3.2-64bit.rpm", "_blank")
                            changeCurrentStep(2)
                        }}>
                        Linux RPM 64-bit >>
                    </EuiPanel>
                    <EuiPanel style={{background: backgroundString}}
                        onClick={() => {
                            window.open("https://csinstallscripts.blob.core.windows.net/agents/filebeat-7.3.2-32bit.rpm", "_blank")
                            changeCurrentStep(2)
                        }}>
                        Linux RPM 32-bit >>
                    </EuiPanel>
                </Fragment>
                break;
            case 'windows':
                newDownloadContent = <Fragment>
                    <EuiPanel style={{background: backgroundString}}
                        onClick={() => {
                            window.open("https://csinstallscripts.blob.core.windows.net/agents/winlogbeat-7.3.2-64bit.zip", "_blank")
                            changeCurrentStep(2)
                        }}>
                        64-Bit >>
                    </EuiPanel>
                    <EuiPanel style={{background: backgroundString}}
                        onClick={() => {
                            window.open("https://csinstallscripts.blob.core.windows.net/agents/winlogbeat-7.3.2-32bit.zip", "_blank")
                            changeCurrentStep(2)
                        }}>
                        32-Bit >>
                    </EuiPanel>
                </Fragment>
                break;
            default:
                newDownloadContent = <EuiPanel>
                    Please select a log source from step 1
                </EuiPanel>
                break;
        }

        changeDownloadContent(newDownloadContent)

    },[logSource])

    const panels = [
        <Fragment>
            <EuiPanel style={{background: backgroundString}}
                onClick={() => {
                    changeLogSource('file')
                    changeCurrentStep(1)
                }}>
                Log Files >>
            </EuiPanel>
            <EuiPanel style={{background: backgroundString}}
                onClick={() => {
                    changeLogSource('windows')
                    changeCurrentStep(1)
                }}>
                Windows Event Logs >>
            </EuiPanel>
        </Fragment>,

        downloadContent,

        <Fragment>
            <EuiPanel
                onClick={() => window.open("https://gettingstarted.cybersift.io/docs/TOC.md", "_blank")}>
                CyberSift Documentation : Gathering windows event logs
            </EuiPanel>
            <EuiPanel
                onClick={() => window.open("https://gettingstarted.cybersift.io/docs/TOC.md", "_blank")}>
                CyberSift Documentation : Gathering file-based logs
            </EuiPanel>
        </Fragment>,
    ]

    return <Fragment>
        <EuiStepsHorizontal steps={steps} />
        {panels[currentStep]}
    </Fragment>

}

export default DeployAgents