import React, {useLayoutEffect} from 'react'

const MainSidebar = (props) => {

    const handleSelection = (view_name) => () => {
      if (view_name === "documentation"){
        window.open("https://gettingstarted.cybersift.io/docs/TOC.md", "_blank")
      } else {
        props.handleSelection({
          name: view_name
      })
      }        
    }

    useLayoutEffect(()=>{
        if (props.active){
            Array.from(document.getElementsByClassName("treeview")).forEach(element => {
                element.classList.remove("active")            
            });
            if (document.getElementById(`sidebar-${props.active}`)){
                document.getElementById(`sidebar-${props.active}`).classList.add("active")
            } 
        }        
    },[props.active])

    useLayoutEffect(()=>{
      if (props.login){
          window.$("body").addClass('sidebar-collapse');
      }        
  },[props.login])

    return <aside className="main-sidebar">
    <section className="sidebar">
      <ul className="sidebar-menu" data-widget="tree">
        <li id="sidebar-operations-center" className="treeview" onClick={handleSelection("operations-center")}>
          <a href="/#">
            <i className="iconh i-settings"></i> <span>Operations Center</span>
          </a>
        </li>
        <li id="sidebar-network" className="treeview" onClick={handleSelection("network")}>
          <a href="/#">
            <i className="iconh i-network"></i> <span>Network</span>
          </a>
        </li>
        <li id="sidebar-dashboard" className="treeview" onClick={handleSelection("dashboard")}>
          <a href="/#">
            <i className="iconh i-dashboard"></i> <span>Dashboard</span>
          </a>
        </li>
        <li id="sidebar-analytics" className="treeview" onClick={handleSelection("analytics")}>
          <a href="/#">
            <i className="iconh i-analytics"></i> <span>Analytics</span>
          </a>
        </li>
        <li id="sidebar-configure" className="treeview" onClick={handleSelection("configure")}>
          <a href="/#">
            <i className="iconh i-configure"></i> <span>Configure</span>
          </a>
        </li>
        <li id="sidebar-deploy" className="treeview" onClick={handleSelection("deploy")}>
          <a href="/#">
            <i className="iconh i-deploy"></i> <span>Deploy</span>
          </a>
        </li>
        <li id="sidebar-reports" className="treeview" onClick={handleSelection("documentation")}>
          <a href="/#">
            <i className="iconh i-reports"></i> <span>Documentation</span>
          </a>
        </li>
      </ul>
    </section>
  </aside>
}

export default MainSidebar