import React, {useReducer, useEffect, useState} from 'react';
import MainHeader from './components/mainHeader';
import MainSidebar from './components/mainSidebar';
import ContentHeader from './components/contentHeader';
import Content from './components/content';
import Dashboard from './components/contentScreens/dashboard';
import Network from './components/contentScreens/network';
import OperationsCenter from './components/contentScreens/operationsCenter';
import Axios from 'axios';
import CONFIG from './config';
import Login from './components/contentScreens/login';
import Configuration from './components/contentScreens/configuration';
import Analytics from './components/contentScreens/analytics';
import DeployAgents from './components/contentScreens/deployAgents';
import LoginContext from './contexts/loginContext';

const viewReducer = (state, action) => {

  switch (action.name) {
    case "dashboard":
      return {
        'name': 'dashboard',
        'header': "Dashboard",
        'main': <Dashboard />
      }
    case "configure":
      return {
        'name': 'configure',
        'header': "Configuration",
        'main': <Configuration />
      }
    case "network":
      return {
        'name': 'network',
        'header': "Network",
        'main': <Network />
      }
    case "operations-center":
        return {
          'name': 'operations-center',
          'header': "Operations Center",
          'main': <OperationsCenter />
        }
    case "analytics":
        return {
          'name': 'analytics',
          'header': "Analytics",
          'main': <Analytics />
        }
    case "deploy":
      return {
        'name': 'deploy',
        'header': "Deploy Agents",
        'main': <DeployAgents />
      }
    case "login":
        return {
          'name': 'login',
          'header': "Login",
          'main': <Login handleSuccessfulLogin={action.handleSuccessfulLogin}/>
        }
    default:
      return <Login />
  }

}


const App = () => {
  const [username, changeUsername] = useState()

  const handleSuccessLogin = (user) => {
    console.log("Successful login!")
    changeUsername(user)
    currentViewDispatch({
      'name': 'operations-center'
    })
  }

  const initialView = viewReducer(null, {
    name: "login",
    handleSuccessfulLogin: handleSuccessLogin
  })

  Axios.defaults.withCredentials = true;
  const [currentView, currentViewDispatch] = useReducer(viewReducer, initialView)

  useEffect(()=>{
    console.log(CONFIG.mode)
    if (CONFIG.mode ==='DEMO') {
      currentViewDispatch({
        'name': 'operations-center'
      })      
    } else {
      Axios.get(CONFIG.api.checkValidLogin).then(resp => {
        if (resp.status == 200) {
          currentViewDispatch({
            'name': 'operations-center'
          })
        }        
      }).catch(_ => console.log("User not logged in"))
    }
  },[])
  
  return (
    <div>
      <MainHeader login={currentView.name==="login"}/>
            
      <MainSidebar login={currentView.name === "login"} active={currentView.name} handleSelection={currentViewDispatch}/>


      <div className="content-wrapper" id="mainContent">
        <LoginContext.Provider value={{
          loggedInUser: username
        }}>
          <ContentHeader content={currentView.header} link={currentView.link}/>
          <Content>
            {currentView.main}
          </Content>
        </LoginContext.Provider>        
      </div>
    </div>
  );
}

export default App;
